import BaseModel from '@models/Model'
import { DateTime } from 'luxon'
import BusinessDba from '@models/BusinessDba'

export interface IBusiness {
  id: number
  name: string
  nickname: string
  createdAt: string
  updatedAt: string
  businessDbas: Array<BusinessDba>

  conceptId: number
  conceptBannerLogoUrl?: string
}

class Business extends BaseModel {
  NAME = 'Business'

  id!: number // This should always exist super important 
  name!: string // This should always exist
  nickname!: string // This should always exist
  createdAt?: string
  createdAt_lux?: DateTime
  updatedAt?: string
  updatedAt_lux?: DateTime
  businessDbas?: BusinessDba[]

  conceptId!: number
  conceptBannerLogoUrl?: string

  constructor (data: IBusiness) {
    console.log('Business model data @Business.ts', data)
    // Object.keys(data).forEach(key => { (data as IIndexable)[key] = (data as IIndexable)[key] === null ? undefined : (data as IIndexable)[key] })
    super(data)
    Object.assign(this, data)
    this.createdAt_lux = data.createdAt ? DateTime.fromISO(data.createdAt) : undefined
    this.updatedAt_lux = data.updatedAt ? DateTime.fromISO(data.updatedAt) : undefined

    if (data.businessDbas) {
      this.setBusinessDbas(data.businessDbas)
    }
  }

  setBusinessDbas (elements: any) {
    this.businessDbas = elements.map((element : any) => {
      const cleanedBusinessDba = {
        id: element.data.id,
        name: element.data.name,
        dba_slug: element.data.dba_slug,
        createdAt: element.data.createdAt,
        updatedAt: element.data.updatedAt
      }

      return new BusinessDba(cleanedBusinessDba)
    })
  }
}

export default Business