import BoundingBox, { type BoundingBoxLimits } from "@services/map/BoundingBox"
import type MapSession from "@services/map/MapSession"

class Viewport {
  private _mapSession: MapSession
  private _boundingBox!: BoundingBox
  private _initialized: boolean = false

  private onMoveCallbacks: Array<(bbox: BoundingBox, limits: BoundingBoxLimits) => void> = []

  get bbox () { return this._boundingBox }
  get limits () { return this.bbox.limits }

  constructor (mapSession: MapSession) {
    this._mapSession = mapSession
    mapSession.map.on('moveend', () => { this.updateBoundingBox() })
  }

  public initialize () {
    this.setLimitsFromMapSession()
    this._initialized = true
  }
  
  public onMove (cb: (bbox: BoundingBox, limits: BoundingBoxLimits) => void) {
    this.onMoveCallbacks.push(cb)
  }

  private updateBoundingBox () {
    // Prevent the viewport from trying to run callbacks before
    // the map has been initialized with a country. This boolean will
    // be set to true automatically when this class gets initialized after
    // the map is initialized. See event handler in MapSession#constructor.
    if (!this._initialized) {
      return false
    }

    if (this.setLimitsFromMapSession() === true) {
      this.executeOnMoveCallbacks()
    }
  }
  
  private executeOnMoveCallbacks () {
    this.onMoveCallbacks.forEach(cb => cb(this.bbox, this.limits))
  }

  private setLimitsFromMapSession () {
    if (this._mapSession.map === undefined) return
    const newBbox = new BoundingBox({
      top: this._mapSession.map.getBounds().getNorth(),
      left: this._mapSession.map.getBounds().getWest(),
      bottom: this._mapSession.map.getBounds().getSouth(),
      right: this._mapSession.map.getBounds().getEast(),
    })

    if (this._boundingBox === undefined || this._boundingBox.equals(newBbox) === false) {
      this._boundingBox = newBbox
      return true
    }
    return false
  }
}

export default Viewport