import BaseModel from '@models/Model'
import type { DateTime } from 'luxon'

export interface IBusinessDba {
  id: number 
  name: string 
  dba_slug: string
  createdAt: DateTime
  updatedAt: DateTime
}

class BusinessDba extends BaseModel {
  NAME = 'BusinessDba'

  id!: number // This should always exist super important 
  name!: string // This should always exist 
  dba_slug?: number
  createdAt?: DateTime
  updatedAt?: DateTime

  constructor (data: IBusinessDba) {
    Object.keys(data).forEach(key => { (data as IIndexable)[key] = (data as IIndexable)[key] === null ? undefined : (data as IIndexable)[key] })
    super(data)
    Object.assign(this, data)
  }

}

export default BusinessDba