<script setup lang="ts">
	import { onMounted, ref, type Ref } from 'vue'
	import L, { Layer, type LatLngBoundsExpression, type Polygon as LeafletPolygon, Marker, Map as LeafletMap } from 'leaflet'
	import 'leaflet/dist/leaflet.css';
	import { createMapSession }from '@services/map'

	import type MapSession  from '@services/map/MapSession'
	import LocalFalconRenderService from '@frontend/services/map/LocalFalconRenderService'

	const mapSession: Ref<MapSession | undefined> = ref(undefined)
	const LFRS: Ref<LocalFalconRenderService | undefined> = ref(undefined)

	onMounted(async () => {
		const leafletEl = document.getElementById('leaflet-map')
		if (leafletEl !== null) {
			mapSession.value = createMapSession(leafletEl, {leaflet: {lat: 8.428904092875392, lng: 0.9887695312500001}, businessId: 1})
			await mapSession.value.initialize()
			LFRS.value = new LocalFalconRenderService(mapSession.value)
			LFRS.value.renderBusinessFalconPoints()
			LFRS.value?.applyRevenueByNamedArea()
		}
	})

	const handleBlueClick = () => {
		mapSession.value?.WNAFactory.createFillColorTransform(1, "#00008B")
	}

	const handleRedClick = () => {
		mapSession.value?.WNAFactory.createFillColorTransform(1, "#850101")
	}

	const handleHalfOpacityClick = () => {
		mapSession.value?.WNAFactory.createFillOpactiyTransform(1, 0.5)
	}

	const handleFullOpacityClick = () => {
		mapSession.value?.WNAFactory.createFillOpactiyTransform(1, 1)
	}

	const handleOnVisibilityClick = () => {
		mapSession.value?.WNAFactory.createVisibilityTransform(1, true)
	}

	const handleOffVisibilityClick = () => {
		mapSession.value?.WNAFactory.createVisibilityTransform(1, false)
	}

</script>

<template>
	<div>
		This is for you Tyler 
	</div>
	<div id="leaflet-map" style="height: 500px" class=" z-0 m-auto bg-black">
		
	</div>

	<button @click="handleBlueClick()" type="button" class="rounded-md bg-blue-600 px-3 py-2 my-7 text-sm font-semibold text-white shadow-sm dhover:bg-gray-600">Blue</button> 
	<button @click="handleRedClick()" type="button" class="rounded-m bg-red-600 px-3 py-2 my-7 text-sm font-semibold text-white shadow-sm hover:bg-gray-600">Red</button>
	<button @click="handleHalfOpacityClick()" type="button" class="rounded-md bg-black px-3 py-2 my-7 text-sm font-semibold text-white shadow-sm hover:bg-gray-600">Opacity 50%</button>
	<button @click="handleFullOpacityClick()" type="button" class="rounded-md bg-black px-3 py-2 my-7 text-sm font-semibold text-white shadow-sm hover:bg-gray-600">Opacity 100%</button>
	<button @click="handleOnVisibilityClick()" type="button" class="rounded-md bg-black px-3 py-2 my-7 text-sm font-semibold text-white shadow-sm hover:bg-gray-600">Visibility On</button>
	<button @click="handleOffVisibilityClick()" type="button" class="rounded-md bg-black px-3 py-2 my-7 text-sm font-semibold text-white shadow-sm hover:bg-gray-600">Visibility Off</button>


</template>