import L from "leaflet"
import { bbox, buffer, point } from "@turf/turf"
import { debounce, throttle } from "lodash-es"

import Polygon from "@models/Polygon"
import Viewport from "@services/map/Viewport"
import Coordinate from "@services/geometry/Coordinate"
import MapFilterService from "@services/map/MapFilterService"
import BoundingBox, { type BoundingBoxLimits } from "@services/map/BoundingBox"
import WrappedPolygon from "@services/map/WrappedPolygon"
import WrappedPolygonStore from "@services/map/WrappedPolygonStore"
// import WrappedPolygonTransformFactory from "@services/map/WrappedPolygonTransformFactory"
import PolygonSelectionGroup, { type PolygonSelectionGroupOptions } from "@services/map/PolygonSelectionGroup"
import RenderService from "@services/map/RenderService"
import LayerGroup from "@services/map/LayerGroup"

import { getPolygonsInRadius } from "@services/map/utils/getPolygonsInRadius"
import { zoomHomeControl } from "@services/map/utils/zoomHomeControl"


export type MapSessionInitializationOptions = {
  mapPadding?: { top?: number, right?: number, bottom?: number, left?: number },
  centerLat?: number;
  centerLng?: number;
  zoomLevel?: number;
  onMoveLoad?: boolean;
  centerBbox?: BoundingBoxLimits | BoundingBox;
}

const DEFAULT_MAP_SESSION_INITIALIZATION_OPTIONS = {
  loadCountryOptions: true,
  onMoveLoad: true,
}

class MapSession {
  private _id: string
  private _options: MapSessionInitializationOptions = DEFAULT_MAP_SESSION_INITIALIZATION_OPTIONS
  private _initialized = false

  private _map: L.Map
  private _tileLayer!: L.TileLayer
  private _grayBaseMap!: L.LayerGroup
  private _currentBaseMap!: L.LayerGroup | L.TileLayer

  private _viewport: Viewport
  private _wrappedPolygonStore: WrappedPolygonStore = new WrappedPolygonStore(this)
  private _mapFilterService: MapFilterService = new MapFilterService(this)
  // private _wrappedPolygonTransformFactory = new WrappedPolygonTransformFactory(this)
  private _polygonSelectionGroups: { [key: string]: PolygonSelectionGroup } = {}
  private _definedLayerGroups: Array<LayerGroup> = []
  private _renderService: RenderService

  private _onMapClickCallbacks: Array<(point: Coordinate, polygons: Array<WrappedPolygon>) => void> = []
  private _onPolygonClickCallbacks: Array<(polygon: WrappedPolygon) => void> = []
  private _onPolygonHoverCallbacks: Array<(polygon: WrappedPolygon) => void> = []
  private _onPolygonsLoadedCallbacks: Array<() => void> = []

  private _onPopupCloseCallbacks: Array<() => void> = []

  private _onMoveLoad: boolean | undefined

  get id () { return this._id }
  get options () { return this._options }
  get initialized () { return this._initialized }

  get map() { return this._map }
  get tileLayer () { return this._tileLayer }
  get renderService () { return this._renderService }
  get definedLayerGroups () { return this._definedLayerGroups }
  get zoomLevel () { return this._map.getZoom() }

  get mapPadding () { return this._options.mapPadding }
  private get mapPaddingTopLeft () { return [this.mapPadding?.left || 0, this.mapPadding?.top || 0] as [number, number]}
  private get mapPaddingBottomRight () { return [this.mapPadding?.right || 0, this.mapPadding?.bottom || 0] as [number, number]}

  get wrappedPolygons () { return this._wrappedPolygonStore.wrappedPolygons }
  get visibleWrappedPolygons () { return this._wrappedPolygonStore.visibleWrappedPolygons }

  // Internal services. These should not be accessed externally
  // These getters exist so the other internal services can access each other.
  // If an external services needs something form these, it should be exposed
  // through a public getter on MapSession.
  get wrappedPolygonStore () { return this._wrappedPolygonStore }
  // get wrappedPolygonTransformFactory () { return this._wrappedPolygonTransformFactory }

  // External services. These are used by other internal services, but are
  // also accessible by external code.
  get viewport () { return this._viewport }
  get mapFilterService () { return this._mapFilterService }
  get selectionGroups () { return this._polygonSelectionGroups }

  private _fitToContent: (() => void) | undefined

  constructor(id: string, container: HTMLElement) {
    this._fitToContent = debounce(this._fitToContent_NOT_DEBOUNCED, 100)

    this._id = id
    this._map = L.map(container, { zoomControl: false, zoomSnap: 0.1 })
    this._renderService = new RenderService(this)

    this._initializeBaseMaps();
    this.setBaseMap('tile');

    this._viewport = new Viewport(this)
    this._viewport.onMove(async (bbox) => {
      if (this._onMoveLoad) {
        // await this.loadAdminLayerPolygons();
      }
    });

    this._map.on('click', (e: L.LeafletMouseEvent) => {
      const polys = this.polygonsAtCoordinate(e.latlng.lat, e.latlng.lng)
      this._onMapClickCallbacks.forEach(fn => fn(new Coordinate([e.latlng.lat, e.latlng.lng]), polys))
      this._onPolygonClickCallbacks.forEach(fn => polys.forEach(fn))
    })

    const debouncedMouseMoveHandler = throttle((e: L.LeafletMouseEvent) => {
      const polys = this.polygonsAtCoordinate(e.latlng.lat, e.latlng.lng)
      this._onPolygonHoverCallbacks.forEach(fn => polys.forEach(fn))
    }, 100)
    this._map.on('mousemove', debouncedMouseMoveHandler)

    this._map.on('popupclose', () => {
      this._onPopupCloseCallbacks.forEach(fn => fn());
    })

    // EventBus.subscribe('kestrel:country-set', (payload: { country: Country, mapSessionId?: string }) => {
    //   // console.log('kestrel:country-set received', payload)
    //   if (payload.mapSessionId !== undefined && this.id !== payload.mapSessionId) {
    //     return
    //   }

    //   const { country } = payload
    //   if (country.centroid !== undefined) {
    //     // Load admin layers from the country into the MapSession
    //     // This is so we can handle visibility states of the levels, so
    //     // the map sessions knows which levels to request polygons for when
    //     // new polygons are requested.

    //     this._adminLayers = this.currentCountry.adminLayers.reduce((acc, adminLayer) => {
    //       // If the admin layer is already in the map session, use the existing visible state
    //       // Otherwise, use the default of false
    //       acc[adminLayer.adminLevel] = { adminLayer, visible: false }
    //       return acc
    //     }, {} as { [key: number]: { adminLayer: AdminLayer, visible: boolean } })

    //     // Set the default viewport position to the country centroid
    //     // If the viewport was set manually by the user, we don't override it
    //     if (this._options.centerBbox !== undefined) {
    //       this.fitBounds(this._options.centerBbox)
    //     } else if (
    //       this._options.centerLat !== undefined &&
    //       this._options.centerLng !== undefined &&
    //       this._options.zoomLevel !== undefined
    //     ) {
    //       // Set the viewport to the provided center and zoom level
    //       this.setView(this._options.centerLat, this._options.centerLng, this._options.zoomLevel)
    //     } else {
    //       // Set the default viewport position to the country centroid
    //       this.setView(country.centroid.lat, country.centroid.lng, 10)
    //     }

    //     this._viewport.initialize()
    //   }

    //   this._onCountrySetCallbacks.forEach(fn => fn(this.currentCountry))
    // })
  }

  public addPolygon (polygon: Polygon) {
    const wp = new WrappedPolygon(polygon, this)
    this._wrappedPolygonStore.addToCache([wp])
    return wp
  }

  private _initializeBaseMaps() {
    this._tileLayer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      minZoom: 4,
      maxZoom: 16,
    });

    // Initialize the gray base map layer group
    this._grayBaseMap = L.layerGroup();

    const grayMapLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
      minZoom: 4,
      maxZoom: 16,
    }).addTo(this._grayBaseMap);
  }

  public setBaseMap(baseMap: 'tile' | 'gray') {
    console.log(`Switching to base map: ${baseMap}`);
    
    if (this._currentBaseMap) {
        console.log('Removing current base map');
        this._map.removeLayer(this._currentBaseMap);
    }
    this.map.invalidateSize();

    if (baseMap === 'tile') {
        console.log('Setting base map to tile layer');
        this._currentBaseMap = this._tileLayer;
    } else if (baseMap === 'gray') {
        console.log('Setting base map to gray layer');
        this._currentBaseMap = this._grayBaseMap;
    }

    if (this._currentBaseMap) {
        console.log('Adding selected base map to the map');
        this._currentBaseMap.addTo(this._map);
    }
  }

  public mergeOptions(options: Partial<MapSessionInitializationOptions>) {
    this._options = {
      ...DEFAULT_MAP_SESSION_INITIALIZATION_OPTIONS,
      ...this.options,
      ...options,
    }
  }

  async initialize (options: MapSessionInitializationOptions = DEFAULT_MAP_SESSION_INITIALIZATION_OPTIONS) {
    this._options = { ...DEFAULT_MAP_SESSION_INITIALIZATION_OPTIONS, ...this._options, ...options }
    // console.log('initializing map session', this._options)

    // // Set initial view if coordinates and zoom level are provided
    // if (this.options.centerLat !== undefined && this.options.centerLng !== undefined && this.options.zoomLevel !== undefined) {
    //   console.log('manually setting the viewport location', this.options)
    //   this.setView(this.options.centerLat, this.options.centerLng, this.options.zoomLevel);
    // }

    // Set initial view if coordinates and zoom level are provided
    if (this.options.centerLat !== undefined && this.options.centerLng !== undefined && this.options.zoomLevel !== undefined) {
      this.setView(this.options.centerLat, this.options.centerLng, this.options.zoomLevel);
    }

    if (this.options.centerBbox !== undefined) {
      // setting centerBbox
      this.fitBounds(this.options.centerBbox)
    }

    this._viewport.initialize()

    this._onMoveLoad = this.options.onMoveLoad
    this._initialized = true
  }


  //// START ADMIN LAYER MANAGEMENT
  // public async showAdminLayer (adminLevel: AdminLevel, options: { parentAdminCode?: string, adminCode?: string } = {}) {
  //   this._requireCountryIso3CodeSet()
  //   this.setAdminLayerVisibility(adminLevel, true)
    
  //   this._adminCode = options.adminCode
  //   this._parentAdminCode = options.parentAdminCode

  //   return this.loadAdminLayerPolygons()
  // }

  // public async showRegionalAdminLayer () {
  //   this._requireCountryIso3CodeSet()
  //   const regionalAdminLevelNumber = this.currentCountry.regionalAdminLayer?.adminLevel
  //   if (regionalAdminLevelNumber === undefined) {
  //     throw new Error('Country does not have a regional admin layer')
  //   }
  //   return this.showAdminLayer(regionalAdminLevelNumber)
  // }

  // public async showVillageAdminLayer () {
  //   this._requireCountryIso3CodeSet()
  //   const villageAdminLevelNumber = this.currentCountry.villageAdminLayer?.adminLevel
  //   if (villageAdminLevelNumber === undefined) {
  //     throw new Error('Country does not have a village admin layer')
  //   }
  //   return this.showAdminLayer(villageAdminLevelNumber)
  // }

  // public hideAdminLayer (adminLevel: AdminLevel) {
  //   this.setAdminLayerVisibility(adminLevel, false)
  // }

  // public setAdminLayerVisibility (adminLevel: AdminLevel, visible: boolean) {
  //   this._requireInitialization()
  //   this._adminLayers[adminLevel].visible = visible

  //   // Inform the wrapped admin layer polygons of the change
  //   // to the visibility of their admin layer.
  //   this._adminLayerPolygonStore.getForAdminLevel(adminLevel)
  //     .filter(walp => walp.visible !== visible)
  //     .forEach(walp => {
  //       walp.adminLayerVisibility = visible
  //     })
  // }

  // public async loadAdminLayerPolygons (adminLevels?: Array<AdminLevel>) {
  //   this._requireCountryIso3CodeSet()

  //   const parentData = { parentAdminCode: this._parentAdminCode, adminCode: this._adminCode }

  //   const adminLevelsToRequest = adminLevels || this._visibleAdminLevelNumbers()
  //   const promises = adminLevelsToRequest.map(adminLevel => {
  //     return this.wrappedAdminLayerPolygonStore.requestForBoundingBox(adminLevel, this._viewport.bbox.scale(1.5), parentData)
  //   })
    
  //   const results = await Promise.all(promises);

  //   this._onPolygonsLoadedCallbacks.forEach(fn => fn());

  //   return results;
  // }
  

  // public getPolygonsForAdminLevel (adminLevel: AdminLevel) {
  //   return this.wrappedAdminLayerPolygonStore.getForAdminLevel(adminLevel)
  // }
  //// END ADMIN LAYER MANAGEMENT


  //// START POLYGON SELECTION INTERFACE
  public createPolygonSelectionGroup (id: string, options: PolygonSelectionGroupOptions = {}) {
    if (this._polygonSelectionGroups[id] === undefined) {
      this._polygonSelectionGroups[id] = new PolygonSelectionGroup(this, id, options)
    }
    return this._polygonSelectionGroups[id]
  }

  public getPolygonSelectionGroup (id: string): PolygonSelectionGroup | undefined {
    return this._polygonSelectionGroups[id]
  }
  //// END POLYGON SELECTION INTERFACE


  //// START EXTERNAL HELPER METHODS - These methods are purely helper functions
  // that the external code can execute to perform some specific filter or operation.
  public flyTo (lat: number, lng: number, zoomLevel: number) {
    // NOTE: This method does not factor in the map padding
    if (!this._map) return
    this._map.flyTo([lat, lng], zoomLevel)
  }

  public flyToBounds (bbox: BoundingBox) {
    // NOTE: This method DOES factor in the map padding
    if (!this._map) return
    this._map.flyToBounds(bbox.asLatLngBoundsExpression, { paddingTopLeft: this.mapPaddingTopLeft, paddingBottomRight: this.mapPaddingBottomRight })
  }

  public setView (lat: number, lng: number, zoomLevel: number) {
    // NOTE: This method does not factor in the map padding
    if (!this._map) return
    this._map.setView([lat, lng], zoomLevel)
  }

  public fitBounds (bbox: BoundingBox | BoundingBoxLimits) {
    // NOTE: This method DOES factor in the map padding
    if (!this._map) return
    if (!(bbox instanceof BoundingBox)) {
      bbox = new BoundingBox(bbox)
    }
    this._map.fitBounds((bbox as BoundingBox).asLatLngBoundsExpression, { paddingTopLeft: this.mapPaddingTopLeft, paddingBottomRight: this.mapPaddingBottomRight })
  }

  public centerOnPoint (lat: number, lng: number, radiusKm: number) {
    const centerPoint = point([lng, lat])
    const buffered = buffer(centerPoint, radiusKm, { units: 'kilometers' })
    const [expandedSwLng, expandedSwLat, expandedNeLng, expandedNeLat] = bbox(buffered)
    this.fitBounds(new BoundingBox({
      top: expandedNeLat,
      bottom: expandedSwLat,
      left: expandedSwLng,
      right: expandedNeLng,
    }))
  }

  /**
   * Given a latitude and longitude, return the polygons that are within the given radius.
   * @param lat Latitude
   * @param lng Longitude
   * @param radius Radius in kilometers to include polygons within
   * @returns Returns a list of polygons that are within the given radius
   */
  public getPolygonsInRadius (lat: number, lng: number, radius: number) {
    return getPolygonsInRadius(this._wrappedPolygonStore.wrappedPolygons, lat, lng, radius)
  }

  /**
   * Given a latitude and longitude, return the currently rendered polygons that
   * contain the given coordinate.
   * @param lat Latitude
   * @param lng Longitude
   * @returns Returns an array of rendered polygons that contain the given coordinate
   */
  public polygonsAtCoordinate (lat: number, lng: number) {
    return this.wrappedPolygonStore.getAtCoordinate(lat, lng)
  }

  public addZoomControl(): void {
    L.control.zoom({ position: 'topleft' }).addTo(this._map)
  }

  public addZoomHomeControl(lat: number, lng: number, zoom: number): void {
    zoomHomeControl({
      position: 'topleft',
      homeCoordinates: L.latLng(lat, lng),
      homeZoom: zoom
    }).addTo(this._map);
  }
  //// END EXTERNAL HELPER METHODS - These methods are purely helper functions


  //// START EXTERNAL ONE-OFF RENDER METHODS
  public renderCircle (lat: number, lng: number, radius: number, options: Partial<L.CircleMarkerOptions> = {}) {
    const circle = L.circleMarker([lat, lng], { radius, ...options })
    circle.addTo(this._map)
    return circle
  }

  

  public renderBox (bbox: BoundingBox, options: L.PolylineOptions = {}) {
    const rect = L.rectangle(bbox.asLatLngBoundsExpression, options)
    rect.addTo(this._map)
    return rect
  }
  //// END EXTERNAL ONE-OFF RENDER METHODS


  //// START CALLBACK REGISTRATION
  public onMapClick (fn: (point: Coordinate, polygons: Array<WrappedPolygon>) => void) {
    this._onMapClickCallbacks.push(fn)
  }

  public offMapClick (fn: (point: Coordinate, polygons: Array<WrappedPolygon>) => void) {
    this._onMapClickCallbacks = this._onMapClickCallbacks.filter(cb => cb !== fn)
  }

  /**
   * Register a callback to be executed when a polygon is clicked.
   * NOTE: Use this only when you want the same behavior for all polygons clicked.
   * If you want targeted click handlers, use the `onPolygonClick` registration
   * method on the WrappedPolygon.
   */
  public onPolygonClick (fn: (polygon: WrappedPolygon) => void) {
    this._onPolygonClickCallbacks.push(fn)
  }

  /**
   * Register a callback to be executed when an admin layer polygon is hovered over.
   * NOTE: Use this only when you want the same behavior for all polygons hovered over.
   * If you want targeted hover handlers, use the `onPolygonHover` registration
   * method on the WrappedPolygon.
   */
  public onPolygonHover (fn: (polygon: WrappedPolygon) => void) {
    this._onPolygonHoverCallbacks.push(fn)
  }

  public onPolygonLoaded (fn: (walp: WrappedPolygon) => void) {
    this._wrappedPolygonStore.onWrappedPolygonAdded(fn)
  }

  public onPolygonsLoaded(fn: () => void) {
    this._onPolygonsLoadedCallbacks.push(fn);
  }

  /**
   * Show a popup at the specified latitude and longitude with the given HTML content.
   */
  public showPopup(lat: number, lng: number, contentDiv: HTMLElement) {
    L.popup({ autoPan: false }).setLatLng([lat, lng])
            .setContent(contentDiv)
            .openOn(this._map);
  }

  public onPopupClose(fn: () => void) {
    this._onPopupCloseCallbacks.push(fn);
  }

  public offPopupClose(fn: () => void) {
    this._onPopupCloseCallbacks = this._onPopupCloseCallbacks.filter(cb => cb !== fn);
  }
  //// END CALLBACK REGISTRATION





  //// START PRIVATE HELPER METHODS
  private _requireInitialization (): asserts this is { initialized: true } {
    if (!this.initialized) {
      throw new Error('MapSession must be initialized before use')
    }
  }

  private _fitToContent_NOT_DEBOUNCED () {
    const allRenderedPolygons = this._wrappedPolygonStore.wrappedPolygons.filter(wp => wp.visible)
    const allRenderedPolygonsBounds = allRenderedPolygons.map(wp => wp.polygon.boundingBox).filter(bbox => bbox !== undefined)
    if (allRenderedPolygonsBounds.length === 0) {
      return
    }

    const top = Math.max(...allRenderedPolygonsBounds.map(bbox => bbox.top))
    const bottom = Math.min(...allRenderedPolygonsBounds.map(bbox => bbox.bottom))
    const left = Math.min(...allRenderedPolygonsBounds.map(bbox => bbox.left))
    const right = Math.max(...allRenderedPolygonsBounds.map(bbox => bbox.right))

    if (top !== undefined && bottom !== undefined && left !== undefined && right !== undefined) {
      this.fitBounds({ top, bottom, left, right })
    }
  }
  //// END PRIVATE HELPER METHODS
}

export default MapSession