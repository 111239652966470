import 'jsonapi-parse'
import axios from 'axios'
import { camelCaseKeys, correctIdDataTypes, snakeCaseKeys } from '@utils'

const baseApiUrl = '/api'
const axiosInstance = axios.create()

const metaCsrfEl = document.querySelector('meta[name="csrf-token"]')
if (!metaCsrfEl) {
  throw new Error('No CSRF token found')
}

export const instanceDefaults = {
  baseURL: baseApiUrl,
  commonHeaders: [
    {
      key: 'X-Requested-With',
      value: 'XMLHttpRequest',
    },
    {
      key: 'X-CSRF-Token',
      value: metaCsrfEl.getAttribute('content') as string
    }
  ]
}

axiosInstance.defaults.baseURL = instanceDefaults.baseURL
instanceDefaults.commonHeaders.forEach((commonHeader) => {
  axiosInstance.defaults.headers.common[commonHeader.key] = commonHeader.value
})

// Ensure all keys going to the API are snake case
axiosInstance.interceptors.request.use(
  req => {
    if (typeof req.params === 'object') {
      req.params = snakeCaseKeys(req.params)
    }
    if (typeof req.data === 'object') {
      req.data = snakeCaseKeys(req.data)
    }
    return req
  }
)

axiosInstance.interceptors.response.use(
  resp => {
    // NOTE: The deserializer does not correctly cast the `id` column to integer
    // This causes ID matching === elsewhere to fail. For some reason, TS doesn't
    // perform an implicit cast and fix this even though the columns in the models
    // are listed as `number`. This helper method was my solution for now.
    console.log('resp.headers', resp.headers, resp.headers['no_jsonapi_parse'])
    resp.data = correctIdDataTypes(
      camelCaseKeys(
        resp.headers['no_jsonapi_parse'] === undefined
          ? window.jsonapi.parse(resp.data)
          : resp.data
      )
    ) as any // Should explicitly type the response on the other side
    return resp
  },
)

export default axiosInstance