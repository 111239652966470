import type WrappedPolygon from "@services/map/WrappedPolygon"
import type MapSession from "@services/map/MapSession"
import type { WrappedPolygonStyles } from "@services/map/WrappedPolygon"

const DEFAULT_APPLIED_STYLES = {
  color: '#ff0000',
  fillColor: '#ff0000',
} as WrappedPolygonStyles

export type PolygonSelectionGroupOptions = {
  appliedStyles?: WrappedPolygonStyles
}

export default class PolygonSelectionGroup {
  private _id: string

  private _mapSession: MapSession
  private _appliedStyles: WrappedPolygonStyles = DEFAULT_APPLIED_STYLES

  private _selectedPolygons: Array<WrappedPolygon> = []
  private _selectedPolygonsByUuid: { [uuid: string]: WrappedPolygon } = {}

  private _onSelectionCallbacks: Array<(walp: WrappedPolygon) => void> = []
  private _onDeselectionCallbacks: Array<(walp: WrappedPolygon) => void> = []
  private _onSelectionChangeCallbacks: Array<(walps: Array<WrappedPolygon>) => void> = []

  constructor (mapSession: MapSession, id: string, options: PolygonSelectionGroupOptions = {}) {
    this._id = id
    this._mapSession = mapSession

    // Optionally override the selection styles
    if (options.appliedStyles !== undefined) {
      this._appliedStyles = options.appliedStyles
    }
  }

  get id () { return this._id }

  get selectedPolygons () { return this._selectedPolygons }
  get selectedPolygonsByUuid () { return this._selectedPolygonsByUuid }

  get groupStyles () { return this._appliedStyles }
  set groupStyles (s) { this._appliedStyles = s }

  public selectPolygon (walp: WrappedPolygon | number): void {
    if (typeof walp === 'number') {
      const possibleWalp = this._mapSession.wrappedPolygonStore.getById(walp)
      if (possibleWalp === undefined) {
        console.warn(`WrappedPolygon with id ${walp} not found. Could not select.`)
        return
      }
      walp = possibleWalp
    }

    if (this.isPolygonSelected(walp) === false) {
      this._selectedPolygons.push(walp)
      this._selectedPolygonsByUuid[walp.uuid] = walp

      walp['applySelectionStyles'](this)
      this._onSelectionCallbacks.forEach(fn => fn(walp))
      this._onSelectionChangeCallbacks.forEach(fn => fn(this.selectedPolygons))
    }
  }

  public deselectPolygon (walp: WrappedPolygon | number): void {
    if (typeof walp === 'number') {
      const possibleWalp = this._mapSession.wrappedPolygonStore.getById(walp)
      if (possibleWalp === undefined) {
        console.warn(`WrappedPolygon with id ${walp} not found. Could not deselect.`)
        return
      }
      walp = possibleWalp
    }

    if (this.isPolygonSelected(walp) === true) {
      this._selectedPolygons = this._selectedPolygons.filter(p => p.uuid !== walp.uuid)
      delete this._selectedPolygonsByUuid[walp.uuid]

      walp['removeSelectionStyles'](this)
      this._onDeselectionCallbacks.forEach(fn => fn(walp))
      this._onSelectionChangeCallbacks.forEach(fn => fn(this.selectedPolygons))
    }
  }

  public clearSelection (): void {
    this._selectedPolygons.forEach(walp => {
      walp['removeSelectionStyles'](this)
      this._onDeselectionCallbacks.forEach(fn => fn(walp))
    })
    this._selectedPolygons = []
    this._selectedPolygonsByUuid = {}
    this._onSelectionChangeCallbacks.forEach(fn => fn(this.selectedPolygons))
  }

  public isPolygonSelected (walp: WrappedPolygon | number): boolean {
    if (typeof walp === 'number') {
      const possibleWalp = this._mapSession.wrappedPolygonStore.getById(walp)
      if (possibleWalp === undefined) {
        return false
      }
      walp = possibleWalp
    }
    return this.selectedPolygonsByUuid[walp.uuid] !== undefined
  }

  public onSelection (fn: (walp: WrappedPolygon) => void): void {
    this._onSelectionCallbacks.push(fn)
  }

  public onDeselection (fn: (walp: WrappedPolygon) => void): void {
    this._onDeselectionCallbacks.push(fn)
  }

  public onSelectionChange (fn: (walps: Array<WrappedPolygon>) => void): void {
    this._onSelectionChangeCallbacks.push(fn)
  }
}