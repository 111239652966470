<script setup lang="ts">
	import { onMounted, ref, type Ref } from 'vue'
	import 'leaflet/dist/leaflet.css';
	import { createMapSession }from '@services/map'
  import { ConfigurationType } from '@frontend/components/configured_map/types'
	import LocalFalconRenderService from '@frontend/services/map/LocalFalconRenderService'
	import TerritoryRenderService from '@frontend/services/map/TerritoryRenderService'
  
	import type MapSession  from '@services/map/MapSession'
  import  type {Configuration}   from '@frontend/components/configured_map/types'

  const props = defineProps<{
    configurations: Array<Configuration>  
  }>()

  const mapSession: Ref<MapSession | undefined> = ref(undefined)
  const LFRS: Ref<LocalFalconRenderService | undefined> = ref(undefined)
  const TRS: Ref<TerritoryRenderService | undefined> = ref(undefined)
 

	onMounted(async () => {
		console.log("CONFIGURATIONS", props.configurations)
		const leafletEl = document.getElementById('leaflet-map')
		if (leafletEl !== null) {
			mapSession.value = createMapSession(leafletEl, {leaflet: { lat: 28.60420391990026, lng: -81.31102469999999 }, businessId: 1})
			await mapSession.value.initialize()

      props.configurations.forEach(conf => {
        if (conf.type === ConfigurationType.LocalFalconScan) {
          LFRS.value = new LocalFalconRenderService(mapSession.value!, conf.localFalconScanId)
					console.log("POINTS", conf.points)
          LFRS.value.setPoints(conf.points)
          LFRS.value.renderBusinessFalconPoints()
        } else if (conf.type === ConfigurationType.TerritoryMap) {
					TRS.value = new TerritoryRenderService(mapSession.value!)
					console.log("NAMEDAREAS", conf.named_areas)
					TRS.value.setTerritory(conf.named_areas)
					TRS.value.renderTerritory()
        }
      })
		}
	})

</script>

<style>
	.falcon-rank-tooltip {
		background-color: transparent;
		border: none;
		box-shadow: none;
		color: blue;
		font-size:larger;
	}

</style>

<template>
	<div id="leaflet-map" style="height: 1000px" class=" z-0 m-auto  bg-black">					
	</div>
</template>