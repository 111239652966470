import { feature } from "@turf/turf"

import type { GeoJSON } from "geojson"
import type { Position2 } from "@customTypes/gis"

class Geometry {
  data: GeoJSON

  constructor (source: GeoJSON) {
    this.data = source
  }

  get type () { return 'Geometry' }

  get asGeoJson () {
    return this.data
  }

  get asGeoJsonFlipped () {
    const temp = { ...this.data }
    if (temp.type === 'Polygon' || temp.type === 'MultiPolygon') {
      temp.coordinates = temp.coordinates.reverse()
    } else {
      console.warn('Unsupported GeoJSON Type:', this.data.type)
    }
    return temp
  }

  get asTurfFeature () {
    return feature(this.data as GeoJSON.Feature)
  }

  get asCoordinates (): Array<Array<Position2>> { throw new Error('Child class must override `asCoordinates`') }
  get asCoordinatesFlipped (): Array<Array<Position2>> { throw new Error('Child class must override `asCoordinatesFlipped`') }

  toJson () {
    return this.asGeoJson
  }
}

export default Geometry