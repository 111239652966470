import BaseModel from "@models/Model"
import PolygonGeo from "@services/geometry/Polygon"
import type { PolygonSourceData } from "@services/geometry/Polygon"

import type { DateTime } from "luxon"

export interface IPolygon {
  id: number 
  centroidLat: number
  centroidLng: number
  geometry: PolygonSourceData
  createdAt: DateTime
  updatedAt: DateTime
}

export default class Polygon extends BaseModel {
  NAME = 'Polygon'

  id!: number // This should always exist super important  
  centroidLat?: number
  centroidLng?: number
  geometry?: PolygonGeo
  createdAt?: DateTime
  updatedAt?: DateTime

  get boundingBox () { return this.geometry?.boundingBox }

  constructor (data: IPolygon) {
    console.log("data @model/Polygon.ts", data)
    Object.keys(data).forEach(key => { (data as IIndexable)[key] = (data as IIndexable)[key] === null ? undefined : (data as IIndexable)[key] })
    super(data)
    Object.assign(this, data)
    this.geometry = new PolygonGeo(data.geometry)
  }
}