import http from '@http/apiProvider'

export type MapQueryGrouping = "named_area" | "zip_code"
export enum MapQueryMetrics {
  REVENUE_BY_NUM_100K_HH = "revenue_by_num_100k_hh",
}

// @ PARAMS
export type IQueryMapMetricParams <T extends MapQueryMetrics> = {
  businessId: number
  metric: T
  grouping: MapQueryGrouping
} 

// @ RESPONSE
export type MetricResult<T extends MapQueryMetrics> = {
  type: MapQueryGrouping
  id: number
  result: number
  normalized: number
  metadata: Split<T, "_by_", number>
}

export interface IQueryMapMetricResponse<T extends MapQueryMetrics> {
  metric: T
  request_params: {
    business_id : number,
    grouping : MapQueryGrouping
  },
  data: Array<MetricResult<T>>
}

// const s: IQueryMapMetricResponse<MapQueryMetrics.REVENUE_BY_NUM_100K_HH> = {
//   type: MapQueryMetrics.REVENUE_BY_NUM_100K_HH,
//   request_params: {
//     business_id: 1,
//     grouping: 'named_area'
//   },
//   data: [
//     {
//       type: 'named_area',
//       result: 100,
//       metadata: {
//         num_100k_hh: 100,
//         revenue: 100,
//       }
//     }
//   ]
// }

export async function queryMapMetric <T extends MapQueryMetrics> (params: IQueryMapMetricParams<T>) {
  const response = await http.get(`/map_query.json`, { params })
  console.log("response @queryMapMetric", response.data)
  return response.data as IQueryMapMetricResponse<T>
}