import Coordinate from "@services/geometry/Coordinate"
import type WrappedPolygon from "@services/map/WrappedPolygon"

export function getPolygonsInRadius (walps: Array<WrappedPolygon>, lat: number, lng: number, radiusKm: number): Array<WrappedPolygon> {
  return walps.filter(walp => {
    return walp.distanceFromPoint(new Coordinate([lng, lat], true)) <= radiusKm
  })
}

export function polygonIsInRadius (walp: WrappedPolygon, lat: number, lng: number, radiusKm: number): boolean {
  return walp.distanceFromPoint(new Coordinate([lng, lat], true)) <= radiusKm
}
