import type NamedArea from "@models/NamedArea"
import type { LocalFalconScanReportPoint } from "@services/map/LocalFalconRenderService"

export enum ConfigurationType {
  LocalFalconScan = 'local-falcon-scan-report',
  TerritoryMap = 'territory-map'
}

export type NamedAreaConfig = {
  type: ConfigurationType.TerritoryMap
  named_areas: Array<NamedArea>
}

export type LocalFalconScanConfig = {
  type: ConfigurationType.LocalFalconScan
  localFalconScanId: number
  external_google_place_id: number
  radius: number
  points: Array<LocalFalconScanReportPoint>
}

export type Configuration = NamedAreaConfig | LocalFalconScanConfig


