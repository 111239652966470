<script setup lang="ts">
import { onMounted, ref, type Ref } from "vue"
import "leaflet/dist/leaflet.css"
import { createMapSession } from "@services/map"
import { MapQueryMetrics, queryMapMetric } from "@http/MapQuery"
// import LocalFalconRenderService from '@frontend/services/map/LocalFalconRenderService'
import TerritoryRenderService from "@frontend/services/map/TerritoryRenderService"

import type MapSession from "@services/map/MapSession"
import NamedArea, { type INamedArea } from "@models/NamedArea"
import { type IBusiness } from "@models/Business"
import { camelCaseKeys } from "@utils/index"
import type { IZipCode } from "@models/ZipCode"
import ZipCode from "@models/ZipCode"

const props = defineProps<{
  business: IBusiness
  namedAreas?: Array<INamedArea>
  zipCodes?: Array<IZipCode>
}>()

const mapSession: Ref<MapSession | undefined> = ref(undefined)

let namedAreas: Array<NamedArea> = []
// let namedAreas: Array<NamedArea> = props.namedAreas?.map((d) => new NamedArea(camelCaseKeys(d) as INamedArea)) ?? []
let zipCodes: Array<ZipCode> = props.zipCodes?.map((d) => new ZipCode(camelCaseKeys(d) as IZipCode)) ?? []

const hoveredZipCode: Ref<ZipCode | undefined> = ref(undefined)

const TRS: Ref<TerritoryRenderService | undefined> = ref(undefined)
let handleRevenueByNum100kHHClick = async (event: Event) => {}
let toggleTerritory = async (event: Event) => {}

onMounted(async () => {
  // const resp = await queryMapMetric<MapQueryMetrics.REVENUE_BY_NUM_100K_HH>({
  //   metric: MapQueryMetrics.REVENUE_BY_NUM_100K_HH,
  //   businessId: businessData.id,
  //   grouping: "zip_code",
  // });
  // console.log("RESPONSE", resp);

  const leafletEl = document.getElementById("leaflet-map") || undefined
  if (leafletEl === undefined) {
    throw new Error("Leaflet map element not found")
  }

  mapSession.value = createMapSession(leafletEl)
  await mapSession.value.initialize({
    centerLat: 28.60420391990026,
    centerLng: -81.31102469999999,
    zoomLevel: 10,
  })

  zipCodes.forEach((zc) => {
    if (zc.polygon !== undefined) {
      const wp = mapSession.value?.addPolygon(zc.polygon)
      wp?.render()
      wp?.setFillOpacity(0.2)

      wp?.onHoverIn(() => {
        wp?.setFillOpacity(0.5)
        hoveredZipCode.value = zc
      })

      wp?.onHoverOut(() => {
        wp?.setFillOpacity(0.2)
        hoveredZipCode.value = undefined
      })
    }
  })

})

  
  // if (leafletEl !== undefined && props.business !== undefined) {
    

  //   // Named Area Initialization in the case that namedAreasData is not passed in
  //   if (namedAreas === undefined || namedAreas.length === 0) {
  //     console.log("====No Named Areas Passed In=====");
  //     console.log("Initializing Named Areas based on Business through MapSession ...");
  //     await mapSession.value.initialize();
  //   } else {
  //     console.log("====Named Areas Passed In=====");
  //     console.log("Initializing Named Areas from Passed In Data ...");

  //     // namedAreas.forEach((na) => {
  //     //   na.namedAreaElements?.forEach((nae) => {
  //     //     mapSession.value?.addPolygon(nae.geometry)
  //     //   })
  //     // })

  


  //     // TRS.value = new TerritoryRenderService(mapSession.value!);
  //     // TRS.value.renderNamedAreaTerritory();

  //     console.log("NAMEDAREAS @ConfiguredBusinessMap.vue", namedAreas);
  //   }
  // }


  // handleRevenueByNum100kHHClick = async (event: Event) => {
  //   if (TRS.value !== undefined)
  //     if (TRS.value.renderedTerritory === "namedArea") {
  //       TRS.value.applyMetricToTerritory(
  //         MapQueryMetrics.REVENUE_BY_NUM_100K_HH,
  //         "named_area",
  //       )
  //     } else if (TRS.value.renderedTerritory === "zipCode") {
  //       TRS.value.applyMetricToTerritory(
  //         MapQueryMetrics.REVENUE_BY_NUM_100K_HH,
  //         "zip_code",
  //       );
  //     }
  // };

  // toggleTerritory = async (event: Event) => {
  //   if (TRS.value !== undefined) {
  //     if (TRS.value.renderedTerritory == "namedArea") {
  //       TRS.value.unrenderNamedAreaTerritory();
  //       TRS.value.renderZipCodeTerritory();
  //       TRS.value.renderedTerritory = "zipCode";
  //     } else if (TRS.value.renderedTerritory == "zipCode") {
  //       TRS.value.unrenderZipCodeTerritory();
  //       TRS.value.renderNamedAreaTerritory();
  //       TRS.value.renderedTerritory = "namedArea";
  //     }
  //   }
  // };



// });
</script>

<style>
.falcon-rank-tooltip {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: blue;
  font-size: larger;
}
</style>

<template>
  <div class="h-full w-full grid grid-cols-2 gap-4">
    <div class="col-span-1 h-full">
      <div id="leaflet-map" class="z-0 m-auto bg-black h-full"></div>
    </div>
    <div class="flex flex-col col-span-1 h-full">
      <div>
        <span>Zip Code:&nbsp;</span>
        <span class="font-bold">{{ hoveredZipCode?.code }}</span>
      </div>

    </div>
  </div>
  <!-- <button
    @click="handleRevenueByNum100kHHClick"
    type="button"
    class="rounded-md mt-4 bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
  >
    Show Revenue by number of 100k House holds
  </button> -->
  <!-- <button
    @click="toggleTerritory"
    type="button"
    class="rounded-md mt-4 ml-4 bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
  >
    Toggle Territory
  </button> -->
  <!-- <p>{{ TRS && TRS.renderedTerritory }}</p> -->
</template>
