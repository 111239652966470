import { latLng, latLngBounds } from "leaflet"
import { bbox, bboxPolygon, booleanContains, booleanOverlap, booleanWithin, geometry, transformScale } from "@turf/turf"

import type Geometry from "@services/geometry/Geometry"
import type Coordinate from "@services/geometry/Coordinate"
import type Polygon from "@services/geometry/Polygon"

export type BoundingBoxLimits = {
  top: number
  left: number
  bottom: number
  right: number
}

class BoundingBox {
  left: number
  top: number
  right: number
  bottom: number

  constructor (bboxCoords: BoundingBoxLimits) {
    this.top = bboxCoords.top
    this.left = bboxCoords.left
    this.bottom = bboxCoords.bottom
    this.right = bboxCoords.right
  }

  get limits (): BoundingBoxLimits {
    return {
      top: this.top,
      left: this.left,
      bottom: this.bottom,
      right: this.right,
    }
  }

  set limits (l: BoundingBoxLimits) {
    this.top = l.top
    this.bottom = l.bottom
    this.left = l.left
    this.right = l.right
  }

  equals (bbox: BoundingBox): boolean {
    return this.top === bbox.top && this.bottom === bbox.bottom && this.left === bbox.left && this.right === bbox.right
  }

  scale (percentage: number) {
    const bufferedPolygon = transformScale(bboxPolygon([this.left, this.bottom, this.right, this.top]), percentage)
    const [left, bottom, right, top] = bbox(bufferedPolygon)
    return new BoundingBox({ top, bottom, left, right })
  }
 
  fullyContainsGeometry (geom: Geometry) {
    const bboxTurfPoly = bboxPolygon([this.left, this.bottom, this.right, this.top])

    if (geom.type === "Polygon" && geom.asTurfFeature.geometry.type === 'MultiPolygon') {
      let isContained = false
      geom.asTurfFeature.geometry.coordinates.forEach((array) => {
        const partialFeature = geometry('Polygon', array as any[])
        if (booleanContains(bboxTurfPoly.geometry, partialFeature)) {
          isContained = true
        }
      })
      return isContained
    } else if (geom.type === "Polygon" && geom.asTurfFeature.geometry.type === 'Polygon') {
      return booleanContains(bboxTurfPoly.geometry, geom.asTurfFeature)
    } else if (geom.type === "Coordinate") {
      const coordinate = geom as Coordinate
      return booleanContains(bboxTurfPoly.geometry, coordinate.asTurfPoint)
    } else {
      return false
    }
  }

  overlapsGeometry (geom: Geometry) {
    const bboxTurfPoly = bboxPolygon([this.left, this.bottom, this.right, this.top])

    if (geom.type === "Polygon") {
      return booleanOverlap(geom.asTurfFeature, bboxTurfPoly.geometry)
    } else if (geom.type === "Coordinate") {
      const coordinate = geom as Coordinate
      return booleanContains(bboxTurfPoly.geometry, coordinate.asTurfPoint)
    } else {
      return false
    }
  }

  fullyContainedByGeometry (geom: Geometry) {
    const bboxTurfPoly = bboxPolygon([this.left, this.bottom, this.right, this.top])

    if (geom.type === "Polygon" && geom.asTurfFeature.geometry.type === 'MultiPolygon') {
      let isContained = false
      geom.asTurfFeature.geometry.coordinates.forEach((array) => {
        const partialFeature = geometry('Polygon', array as any[])
        if (booleanWithin(bboxTurfPoly.geometry, partialFeature)) {
          isContained = true
        }
      })
      return isContained
    } else if (geom.type === "Polygon" && geom.asTurfFeature.geometry.type === 'Polygon') {
      return booleanWithin(bboxTurfPoly.geometry, geom.asTurfFeature)
    } else if (geom.type === "Coordinate") {
      // No boundingBox Should be able to be within a coordiate.
      return false
    } else {
      return false
    }
  }

  fullyContains(bbox: BoundingBox) {
    return this.fullyContains2D(bbox)
  }

  overlaps(bbox: BoundingBox) {
    return this.overlaps2D(bbox)
  }

  fullyContainedBy(bbox: BoundingBox) {
    return bbox.fullyContains(this)
  }

  // latitude and longitude need to have 180 added to them to remove the affects of
  // negative numbers distorting calculation in methods like, fullyContains, overlaps, etc..
  get leftAdjusted () {
    return this.left + 180
  }

  // latitude and longitude need to have 180 added to them to remove the affects of
  // negative numbers distorting calculation in methods like, fullyContains, overlaps, etc..
  get topAdjusted () {
    return this.top + 180
  }

  // latitude and longitude need to have 180 added to them to remove the affects of
  // negative numbers distorting calculation in methods like, fullyContains, overlaps, etc..
  get rightAdjusted () {
    return this.right + 180
  }

  // latitude and longitude need to have 180 added to them to remove the affects of
  // negative numbers distorting calculation in methods like, fullyContains, overlaps, etc..
  get bottomAdjusted () {
    return this.bottom + 180
  }

  get asLatLngBoundsExpression() {
    const corner1 = latLng(this.top, this.left)
    const corner2 = latLng(this.bottom, this.right)
    return  latLngBounds(corner1, corner2)
  }

  get asTurfFeature () {
    return bboxPolygon([this.left, this.bottom, this.right, this.top])
  }

  get asPolygon () {
    return new Polygon(this.asTurfFeature.geometry)
  }

  private overlaps2D(bbox: BoundingBox) {
    return this.overlapsY1D(bbox) && this.overlapsX1D(bbox)
  }

  private overlapsY1D (bbox: BoundingBox) {
    return this.topAdjusted >= bbox.bottomAdjusted && bbox.topAdjusted >= this.bottomAdjusted
  }

  private overlapsX1D (bbox: BoundingBox) {
    return this.rightAdjusted >= bbox.leftAdjusted && bbox.rightAdjusted >= this.leftAdjusted
  }

  private fullyContains2D(bbox: BoundingBox) {
    return this.fullyContainsY1D(bbox) && this.fullyContainsX1D(bbox)
  }

  private fullyContainsY1D (bbox: BoundingBox) {
    return this.topAdjusted >= bbox.topAdjusted && bbox.bottomAdjusted >= this.bottomAdjusted
  }

  private fullyContainsX1D (bbox: BoundingBox) {
    return this.rightAdjusted >= bbox.rightAdjusted && bbox.leftAdjusted >= this.leftAdjusted
  }
}

export default BoundingBox