import type MapSession from './MapSession'
import type { CircleStyle } from './LeafletService'
import type { Circle as LeafletCirlce} from 'leaflet'

const halfMile = 1609.34 / 2

export type LocalFalconScanReportCompetitorResult = {
  lat: number
  lng: number
  rank: number
}

export type LocalFalconScanReportPoint = {
  lat: number
  lng: number
  found: boolean
  count: number
  rank: number
  results: Array<LocalFalconScanReportCompetitorResult>
}

class LocalFalconRenderService {
  private _mapSession: MapSession
  private _clientBusinessId: number
  private _localFalconScanReportId: number
  private _point?: LocalFalconScanReportPoint[]

  constructor (mapSession: MapSession, localFalconScanReportId: number) {
    this._mapSession = mapSession
    this._clientBusinessId = mapSession.businessId
    this._localFalconScanReportId = localFalconScanReportId
  }

  // Points should can be given to the render service
  setPoints (pointGroup: LocalFalconScanReportPoint[]) {
    this._point = pointGroup
  }

  // Points can be requested from the server
  requestBusinessFalconScan (businessId: number, localFalconScanReportId: number = this._localFalconScanReportId): LocalFalconScanReportPoint[] {
    // console.log('Requesting business area', businessId)
    return []
  }

  renderBusinessFalconPoints (businessId: number = this._clientBusinessId) {
    let falconPoints: LocalFalconScanReportPoint[]
    if (this._point === undefined) {
      falconPoints = this.requestBusinessFalconScan(businessId)
    } else {
      falconPoints = this._point
    }
    

    const RADIUS = 1

    const falconLeafletCircles: Array<LeafletCirlce> = falconPoints.map((falconPointData: LocalFalconScanReportPoint) => {
      console.log('Rendering falcon point', falconPointData)
      const style: CircleStyle = {
        lat: Number(falconPointData.lat),
        lng: Number(falconPointData.lng),
        radius: Number(RADIUS) * halfMile,
        fillColor: (falconPointData.found) ? 'blue' : 'red'
      }

      const circle = this._mapSession.leaflet.buildLeafletCircle(style)

      circle.bindTooltip(falconPointData.rank.toString(),
        {permanent: true, direction:"center", opacity: 1, className: "falcon-rank-tooltip"}
        ).openTooltip()
      return circle
    })

    falconLeafletCircles.forEach((circle) => {
      this._mapSession.leaflet.renderLayer(circle)
    })
  }

}

export default LocalFalconRenderService