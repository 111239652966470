// @ts-ignore -- NOTE: DO NOT REMOVE BOOLEANINTERSECTS
import {
  flip,
  feature,
  bbox,
  polygon as turfPolygon,
  booleanEqual,
  booleanIntersects,
  booleanContains,
  multiPolygon as turfMultipolygon,
  booleanPointInPolygon,
} from '@turf/turf'
import Geometry from "@services/geometry/Geometry"
import Coordinate from "@services/geometry/Coordinate"
import BoundingBox from "@services/map/BoundingBox"

import type { GeoJSON } from 'geojson'
import type { BBox } from '@turf/turf'
import type { Position2 } from '@customTypes/gis'

type PolygonSourceData_GeoJson = GeoJSON
type PolygonSourceData_TurfBBox = BBox
export type PolygonSourceData = PolygonSourceData_GeoJson | PolygonSourceData_TurfBBox

function isGeoJson(data: PolygonSourceData) {
  if (data && typeof data === 'object') {
    return Object.hasOwn(data, 'type') && Object.hasOwn(data, 'coordinates')
  }
  return false
}

function isTurfBBox (data: PolygonSourceData) {
  return Array.isArray(data) && data.length === 4
}

class Polygon extends Geometry {
  constructor (source: PolygonSourceData) {
    if (isGeoJson(source)) {
      super(source as PolygonSourceData_GeoJson)
    } else if (isTurfBBox(source)) {
      source = source as PolygonSourceData_TurfBBox
      super({
        type : "Polygon",
        coordinates: [[[source[2], source[1]], [source[2], source[3]], [source[0], source[3]], [source[0], source[1]], [source[2], source[1]]]]
      })
    } else {
      console.error('Invalid Polygon Type', source)
      throw new Error('Invalid Polygon Type')
    }
  }

  get type () { return 'Polygon' }

  get minX () { return this.asTurfBoundingBox[0] }
  get minY () { return this.asTurfBoundingBox[1] }
  get maxX () { return this.asTurfBoundingBox[2] }
  get maxY () { return this.asTurfBoundingBox[3] }

  get boundingBox () { return new BoundingBox({ top: this.maxY, left: this.minX, bottom: this.minY, right: this.maxX }) }
  get boundingBoxCenter () { return new Coordinate([(this.maxY + this.minY) / 2, (this.maxX + this.minX) / 2]) }

  get asTurfPolygon () {
    const feature = this.asTurfFeature
    if (feature.geometry.type === 'Polygon') {
      return turfPolygon(feature.geometry.coordinates as Array<Array<Position2>>)
    } else if (feature.geometry.type === 'MultiPolygon') {
      return turfMultipolygon(feature.geometry.coordinates as Array<Array<Array<Position2>>>)
    }
  }

  get asTurfBoundingBox () {
    return bbox(this.data)
  }

  get asTurfFeature () {
    return feature(this.data)
  }

  get asCoordinates () {
    if (this.data.type === 'Polygon' || this.data.type === 'MultiPolygon') {
      return this.data.coordinates as Array<Array<Position2>>
    } else {
      console.warn('Unsupported GeoJSON Type:', this.data.type)
      return [[]]
    }
  }

  get asCoordinatesFlipped () {
    if (this.data.type === 'Polygon' || this.data.type === 'MultiPolygon') {
      // NOTE: TurfPosition used to be Position2 -- not sure if we need it yet
      return flip(feature(this.data)).geometry.coordinates as Array<Array<Position2>>
    } else {
      console.warn('Unsupported GeoJSON Type:', this.data.type)
      return [[]]
    }
  }

  get splitPolygons () {
    if (this.data.type === 'Polygon') {
      return [new Polygon(this.data)]
    } else if (this.data.type === 'MultiPolygon') {
      return this.asCoordinates.map(coordinates => new Polygon({ type: 'Polygon', coordinates }))
    } else {
      console.warn('Unsupported GeoJSON Type:', this.data.type)
      return []
    }
  }

  containsCoordinate(coordinate: Coordinate) {
    return booleanPointInPolygon(coordinate.asTurfPoint, this.asTurfFeature)
  }

  hasEqualGeometry(polygon: Polygon) {
    return booleanEqual(this.asTurfFeature, polygon.asTurfFeature)
  }

  geometryIntersects(polygon: Polygon) {
    return booleanIntersects(this.asTurfFeature, polygon.asTurfFeature)
  }

  fullyContainsPolygon (polygon: Polygon) {
    return booleanContains(this.asTurfFeature, polygon.asTurfFeature)
  }
}

export default Polygon