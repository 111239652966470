import { v4 as uuid } from "uuid"
import MapSession from "@services/map/MapSession"

export default MapSession

//// START MAP SESSION MANAGEMENT
const MAP_SESSIONS: IIndexable<MapSession> = {}

export function createMapSession (el: HTMLElement, id: string = uuid()): MapSession {
  if (MAP_SESSIONS[id] === undefined) {
    MAP_SESSIONS[id] = new MapSession(id, el)
  }
  return MAP_SESSIONS[id]
}

export function getMapSession (id: string): MapSession | undefined {
  return MAP_SESSIONS[id]
}

export function destroyMapSession (id: string): void {
  delete MAP_SESSIONS[id]
}
//// END MAP SESSION MANAGEMENT
