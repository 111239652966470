import { queryNamedAreas } from '@http/NamedAreas'
import type { IQueryNamedAreasResponse }   from '@http/NamedAreas'
import type MapSession from './MapSession'
import type NamedArea from '@models/NamedArea'
import { MapQueryMetrics, queryMapMetric } from '@http/MapQuery'
import type { IQueryMapMetricResponse, MapQueryGrouping } from '@http/MapQuery'
import type { MetricResult } from '@http/MapQuery';
import type ZipCode from '@models/ZipCode'
import type { TerritoryType } from '@services/WrappedTerritoryTransform/WrappedTerritoryTransformFactory'
import type WrappedZipCode from '@services/encapsulators/WrappedZipCode'
import type WrappedNamedArea from '@services/encapsulators/WrappedNamedArea'
import type { WrappedTerritory } from '@services/WrappedTerritoryTransform/WrappedTerritoryTransform'


// const metricData = {
//   "Oveido": 27123,
//   "Winter Park": 12345,
//   "Orlando": 52554,
//   "Lake Mary": 32345,
//   "Sanford": 65321
// }

class TerritoryRenderService {
  private _mapSession: MapSession
  private _renderedTerritory: string = "namedArea"
  private _businessId: number

  constructor (mapSession: MapSession) {
    this._mapSession = mapSession
    this._businessId = mapSession.businessId
  }

  get renderedTerritory () { return this._renderedTerritory }
  set renderedTerritory (territory: string) { this._renderedTerritory = territory }

  async renderZipCodeTerritory () {
    console.log("rendering zip code territory")
    if (!this._mapSession.wrappedZipCodeStore){
      throw new Error("!!!Territory not set!!!")
    } 

    this._mapSession.wrappedZipCodeStore.wrappedZipCodesArray.forEach((WZC: WrappedZipCode) => {
      this._mapSession.transformFactory.createVisibilityTransform(WZC.id, true, WZC.type as TerritoryType)
    })
  }

  async renderNamedAreaTerritory () {
    console.log("rendering named area territory")
    if (!this._mapSession.wrappedNamedAreaStore){
      throw new Error("!!!Territory not set!!!")
    }

    console.log("This is the namedAreaTerritories: ", this._mapSession .wrappedNamedAreaStore)
    this._mapSession .wrappedNamedAreaStore.wrappedNamedAreasArray.forEach((WNA: WrappedNamedArea) => {
      console.log("This is the named area: ", WNA, WNA.id, WNA.type)
      this._mapSession.transformFactory.createVisibilityTransform(WNA.id, true, WNA.type as TerritoryType)
    })
  }
  async unrenderZipCodeTerritory () {
    if (!this._mapSession.wrappedZipCodeStore){
      throw new Error("!!!Territory not set!!!")
    } 

    this._mapSession.wrappedZipCodeStore.wrappedZipCodesArray.forEach((WZC: WrappedZipCode) => {
     this._mapSession.transformFactory.createVisibilityTransform(WZC.id, false, WZC.type as TerritoryType)
    })
  }

  async unrenderNamedAreaTerritory () {
    if (!this._mapSession.wrappedNamedAreaStore){
      throw new Error("!!!Territory not set!!!")
    } 
    this._mapSession .wrappedNamedAreaStore.wrappedNamedAreasArray.forEach((WNA: WrappedNamedArea) => {
      this._mapSession.transformFactory.createVisibilityTransform(WNA.id, false, WNA.type as TerritoryType)
    })
  }
  
  async  requestMetricByGrouping (metric: MapQueryMetrics, grouping: MapQueryGrouping): Promise<IQueryMapMetricResponse<MapQueryMetrics>>  {
    const data = await queryMapMetric<typeof metric>({
      metric: metric,
      businessId: this._businessId,
      grouping: grouping, 
    })
    return data;
  }

  async applyMetricToTerritory (metric: MapQueryMetrics, grouping: MapQueryGrouping) {
    const metricData:  IQueryMapMetricResponse<typeof metric> = await this.requestMetricByGrouping(metric, grouping)

    const normalizedMetricData = this.logarithmicTransform(metric, metricData)

    console.log("This is the normalizedMetricData: ", normalizedMetricData)
    this.applyStylesByGrouping(metric, normalizedMetricData, grouping)
  }

  normalizedTransform (metric: MapQueryMetrics, metricData: IQueryMapMetricResponse<typeof metric>) {
    console.log("This is the metricData: ", metricData)
    let highestResult = metricData.data.reduce((acc: MetricResult<typeof metric>, curr: MetricResult<typeof metric>) => {
      return (acc.result > curr.result) ? acc : curr;
    })

    console.log("This is the highestResult: ", highestResult)
    const normalizedMetricData = metricData.data.map((data) => {
      return {...data, normalized: data.result / highestResult.result}
    })

    return normalizedMetricData
  }

  logarithmicTransform (metric: MapQueryMetrics, metricData: IQueryMapMetricResponse<typeof metric>) {
    // Step 1: Transform the data to reduce disparity
    const transformedData = metricData.data.map((data: MetricResult<typeof metric>) => {
      // Adjust data.metric as needed to ensure it's positive, e.g., by adding a constant
      const adjustedValue = data.result + 1; // Example adjustment
      return {...data, metricTransformed: Math.log(adjustedValue)};
    })

    // Step 2: Normalize the transformed data to a 0-1 range
    const transformedValues = transformedData.map(data => data.metricTransformed);
    const minTransformedValue = Math.min(...transformedValues);
    const maxTransformedValue = Math.max(...transformedValues);
    const range = maxTransformedValue - minTransformedValue;

    const normalizedMetricData = transformedData.map(data => {
      const normalizedValue = (data.metricTransformed - minTransformedValue) / range;
      return {...data, normalized: normalizedValue}; 
    })

    return normalizedMetricData
  }


  applyStylesByGrouping (metric: MapQueryMetrics, normalizedMetricData: MetricResult<typeof metric>[], grouping: MapQueryGrouping) {
    // rgb(255,0,0)
    const color1_red = 255
    const color1_green = 0
    const color1_blue = 0

    // rgb(0,255,0)
    const color2_red = 0
    const color2_green = 255
    const color2_blue = 0

    const colorGradientCB = (WT: WrappedTerritory) => {
      const percent = normalizedMetricData.find((data: MetricResult<typeof metric>) => data.id === WT.id)?.normalized || 0
      console.log("This is the percent for " + WT.type + ": " + percent)
      const resultRed = color1_red + percent * (color2_red - color1_red);
      const resultGreen = color1_green + percent * (color2_green - color1_green);
      const resultBlue = color1_blue + percent * (color2_blue - color1_blue);
      // console.log("This is the percent for WT ", percent) 
      console.log("Result Red: ", resultRed, "Result Green: ", resultGreen, "Result Blue: ", resultBlue)
      const fillColor = this.rgbToHex(resultRed, resultGreen, resultBlue)
      console.log("Fill color for " + WT.id + " in call back function is " + fillColor, "TYPE:", WT.type)
      this._mapSession.transformFactory.createFillColorTransform(WT.id, fillColor, WT.type)
      this._mapSession.transformFactory.createFillOpactiyTransform(WT.id, 0.6, WT.type)
      console.log("=====================================")
    }
    
    if (grouping === "zip_code") {
      this._mapSession.applyZipCodeStyles(colorGradientCB)
    } else if (grouping === "named_area") {
      this._mapSession.applyNamedAreaStyles(colorGradientCB)
    }
  }


  rgbToHex(r: number, g: number, b: number): string {
    // Ensure the component values are within the range of 0 to 255
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));

    // Convert each component to a two-character hexadecimal number
    // and concatenate them
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
  }
}

export default TerritoryRenderService