import WrappedPolygon from "@services/map/WrappedPolygon"
import type MapSession from "@services/map/MapSession"

class RenderService {
  private _mapSession: MapSession

  constructor(mapSession: MapSession) {
    this._mapSession = mapSession
  }

  public getAddToTarget(wrapped: WrappedPolygon) {
    let layerGroup = this._mapSession.definedLayerGroups.find(lg => lg.checkFn(wrapped))
    if (!layerGroup) {
      // console.log('DEFAULT getAddToTarget', layerGroup, wrapped)
      const that = this
      layerGroup = {
        get leafletContainer () {
          return that._mapSession.map
        },
        addLayer: (layer: L.Layer) => {
          that._mapSession.map.addLayer(layer)
        },
        removeLayer: (layer: L.Layer) => {
          that._mapSession.map.removeLayer(layer)
        },
        hasLayer: (layer: L.Layer) => {
          return that._mapSession.map.hasLayer(layer)
        },
      }
    }
    return layerGroup
  }

  public addWrappedPolygon(wrapped: WrappedPolygon) {
    wrapped.leafletPolygon?.addTo(this.getAddToTarget(wrapped).leafletContainer)
    return this
  }

  public removeLayer(wrapped: WrappedPolygon, layer: L.Layer) {
    const target = this.getAddToTarget(wrapped)
    return target.leafletContainer.removeLayer(layer)
  }

  public hasLayer(wrapped: WrappedPolygon, layer: L.Layer) {
    const target = this.getAddToTarget(wrapped)
    return target.leafletContainer.hasLayer(layer)
  }
}

export default RenderService