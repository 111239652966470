import RBush from "rbush"

import Polygon from "@models/Polygon"
import WrappedPolygon from "@services/map/WrappedPolygon"
import Coordinate from "@services/geometry/Coordinate"

import type MapSession from "@services/map/MapSession"
import type BoundingBox from "@services/map/BoundingBox"

export default class WrappedPolygonStore {
  _mapSession: MapSession

  // The store has a few different caches for the same data
  // just so the data can be accessed in different ways.
  // We don't really care much about this taking up more space in
  // memory because the data is not that big. This will give us better
  // lookup times.
  _hashCache: { [Interface: number]: WrappedPolygon } = {}
  _leafletIdCache: { [Interface: string]: WrappedPolygon } = {}
  _arrayCache: Array<WrappedPolygon> = []

  _rtree = new RBush<{ minX: number, maxX: number, minY: number, maxY: number, polygonId: number }>()

  _onPolygonAddedCallbacks: Array<(wp: WrappedPolygon) => void> = []
  _onSpecificPolygonAddedCallbacks: { [Interface: number]: Array<(wp: WrappedPolygon) => void> } = {}

  constructor (mapSession: MapSession) {
    this._mapSession = mapSession
  }

  get wrappedPolygons () { return this._arrayCache }
  get visibleWrappedPolygons () { return this._arrayCache.filter(wp => wp.isRendered) }

  public getById (alpId: number): WrappedPolygon | undefined {
    return this._hashCache[alpId]
  }

  public getByLeafletId (leafletId: number) {
    return this._leafletIdCache[leafletId]
  }

  public removePolygon (wp: WrappedPolygon) {
    delete this._hashCache[wp.polygonId!]
    delete this._leafletIdCache[wp.leafletId]
    this._arrayCache = this._arrayCache.filter(p => p.polygonId !== wp.polygonId)
    this._rtree.remove({
      minX: wp.geometry.minX,
      minY: wp.geometry.minY,
      maxX: wp.geometry.maxX,
      maxY: wp.geometry.maxY,
      polygonId: wp.polygonId!,
    })
  }

  // Returns a list of WrappedPolygons that contains a given point.
  // This method on utilizes the local cache of polygons. It does not make a new API
  // request. That assumption is that the needed data is already in the cache for this.
  public getAtCoordinate (lat: number, lng: number, includeUnrendered: boolean = false): Array<WrappedPolygon> {
    // Use the RTree to do a first computation. This will give us a list of
    // polygons that have a bounding box that overlaps with the point, so our search
    // space is reduced.
    const searchResults = this._rtree.search({
      minX: lng, minY: lat,
      maxX: lng, maxY: lat,
    })
    const wpsWithOverlappingBbox = searchResults.map(result => this.getById(result.polygonId))
      .filter(wp => wp !== undefined)

    // Now we can filter the results to only include polygons that contain the point
    const wpsContainingPoint = wpsWithOverlappingBbox.filter(wp => wp!.geometry.containsCoordinate(new Coordinate([lat, lng])))
    if (includeUnrendered === true) {
      // If we want to include unrendered polygons, we can just return the results
      return wpsContainingPoint as Array<WrappedPolygon>
    }

    // Otherwise, we filter out the unrendered polygons
    return wpsContainingPoint.filter(wp => wp !== undefined && wp.isRendered) as Array<WrappedPolygon>
  }

  // Returns a promise that resolves with a list of WrappedPolygons that
  // are within a given bounding box. This method will conditionally make a new API
  // request to get the data that is needed.
  // public async requestForBoundingBox (adminLevel: AdminLevel, boundingBox: BoundingBox, parentData: { parentAdminCode?: string, adminCode?: string } = {}) {
  //   const newPolygonsResponse = await AdminLayerPolygonQueryService
  //     .requestAdminLayerPolygonsForBoundingBox({
  //       countryIso3Code: this._mapSession.currentCountryIso3Code,
  //       adminLevel,
  //       bbox: {
  //         top: boundingBox.top,
  //         left: boundingBox.left,
  //         bottom: boundingBox.bottom,
  //         right: boundingBox.right,
  //       },
  //       simplification: 0,
  //       countryRevision: this._mapSession.countryService.countryRevision,
  //       parentAdminCode: parentData.parentAdminCode,
  //       adminCode: parentData.adminCode
  //     })

  //   const newPolygons = newPolygonsResponse.polygons
  //   const wrappedPolygons = this.wrapIncomingPolygons(newPolygons)

  //   // Evaluate the newly wrapped polygons using the map filter service
  //   this._mapSession.mapFilterService.evaluate(wrappedPolygons)

  //   return wrappedPolygons
  // }

  public onWrappedPolygonAdded (fn: (wp: WrappedPolygon) => void, polygonId?: number) {
    if (polygonId !== undefined) {
      if (this._onSpecificPolygonAddedCallbacks[polygonId] === undefined) {
        this._onSpecificPolygonAddedCallbacks[polygonId] = []
      }
      this._onSpecificPolygonAddedCallbacks[polygonId].push(fn)
    } else {
      this._onPolygonAddedCallbacks.push(fn)
    }
    return () => {
      if (polygonId !== undefined) {
        this._onSpecificPolygonAddedCallbacks[polygonId] = this._onSpecificPolygonAddedCallbacks[polygonId].filter(cb => cb !== fn)
      } else {
        this._onPolygonAddedCallbacks = this._onPolygonAddedCallbacks.filter(cb => cb !== fn)
      }
    }
  }

  public addToCache (wps: Array<WrappedPolygon>) {
    wps.forEach(wp => this.cacheWrappedPolygon(wp))
    wps.forEach(wp => this.runOnPolygonAddedCallbacks(wp))
  }

  private runOnPolygonAddedCallbacks (wp: WrappedPolygon) {
    if (wp.polygonId !== undefined && this._onSpecificPolygonAddedCallbacks[wp.polygonId] !== undefined) {
      this._onSpecificPolygonAddedCallbacks[wp.polygonId].forEach(fn => fn(wp))
    }
    this._onPolygonAddedCallbacks.forEach(fn => fn(wp))
  }



  //// START CACHE MANAGEMENT METHODS
  private addToLeafletIdCache (wp: WrappedPolygon) {
    this._leafletIdCache[wp.leafletId] = wp
  }

  private wrapIncomingPolygons (polygons: Array<Polygon>) {
    return polygons.map(p => {
      const existingWrappedPolygon = this.getById(p.id!)
      if (existingWrappedPolygon === undefined) {
        const newWrappedPolygon = new WrappedPolygon(p, this._mapSession)
        this.cacheWrappedPolygon(newWrappedPolygon)
        this.runOnPolygonAddedCallbacks(newWrappedPolygon)
        return newWrappedPolygon
      } else {
        // Set new data -- in case it has changed
        existingWrappedPolygon.polygon = p
        return existingWrappedPolygon
      }
    })
  }

  public cacheWrappedPolygon (wp: WrappedPolygon) {
    // Cache in array cache
    this._arrayCache.push(wp)

    // Cache in hash cache
    this._hashCache[wp.polygonId!] = wp

    // Cache in leaflet ID cache
    if (wp.leafletId !== undefined) {
      this._leafletIdCache[wp.leafletId] = wp
    }

    // Insert into the RTree for spatial lookup queries
    this._rtree.insert({
      minX: wp.geometry.minX,
      minY: wp.geometry.minY,
      maxX: wp.geometry.maxX,
      maxY: wp.geometry.maxY,
      polygonId: wp.polygonId!,
    })

    return this._hashCache[wp.polygonId!]
  }
  //// END CACHE MANAGEMENT METHODS
}