import L from "leaflet"
import { centerOfMass } from "@turf/turf"
import { v4 as uuid } from "uuid"

import Coordinate from "@services/geometry/Coordinate"

import type MapSession from "@services/map/MapSession"
import type PolygonSelectionGroup from "@services/map/PolygonSelectionGroup"
import type PolygonGeo from "@services/geometry/Polygon"
import type Polygon from "@models/Polygon"

// import './custom-tooltip.css'

export enum WrappedPolygonStyleKeys {
  OPACITY = 'opacity',
  WEIGHT = 'weight',
  COLOR = 'color',
  FILL_COLOR = 'fillColor',
  FILL_OPACITY = 'fillOpacity',
  FILL = 'fill',
}

export type WrappedPolygonStyles = {
  [WrappedPolygonStyleKeys.OPACITY]?: number
  [WrappedPolygonStyleKeys.WEIGHT]?: number
  [WrappedPolygonStyleKeys.COLOR]?: string
  [WrappedPolygonStyleKeys.FILL_COLOR]?: string
  [WrappedPolygonStyleKeys.FILL_OPACITY]?: number
  [WrappedPolygonStyleKeys.FILL]?: boolean
}

type WrappedPolygonVisibilityState = {
  adminLayer: boolean
  filtered: boolean
  self: boolean
}

type WrappedPolygonOptions = {
}

const DEFAULT_WRAPPED_POLYGON_OPTIONS: WrappedPolygonOptions = {
}

export default class WrappedPolygon {
  private _uuid: string = uuid()
  private _polygon!: Polygon

  // This is a reactive object that will trigger a re-render of the polygon
  // when any of the values change. This is used to control the visibility
  // of the polygon. All of the values are set to true by default and all must
  // be true in order for the polygon to be shown.
  // - Admin Layer: Represents the visibility of the entire admin layer
  // - Filtered: The visibility of the polygon after a filter has been applied. Will be toggled by map filters
  // - Self: An override flag. Can be set to false to hide the polygon regardless of the other flags. Rarely will change.
  private _visibilityState: WrappedPolygonVisibilityState = {
    adminLayer: true,
    filtered: true,
    self: true,
  }

  private _appliedFilterStyles: WrappedPolygonStyles = {}
  private _appliedSelectionGroupStyles: { [key: string]: WrappedPolygonStyles } = {}
  private _tooltip: L.Tooltip | undefined = undefined;

  // Style properties to render the polygon with. This can only be mutated
  // through issues a series of transform to the wrapped admin layer polygon.
  private _style: WrappedPolygonStyles = {
    opacity: 1,
    weight: 2,
    color: '#3885e9',
    fillColor: '#3885E9',
    fillOpacity: 0.2,
    fill: true,
  }
  private _defaultStyle: WrappedPolygonStyles = { ...this._style }

  private _leafletPolygon: L.Polygon | undefined = undefined
  private _leafletBoundingBox: L.Rectangle | undefined = undefined
  private _mapSession: MapSession
  private _leafletId: string = ''
  private _isImmutable: boolean = false

  private _onClickCallbacks: Array<(walp: WrappedPolygon, point: Coordinate) => void> = []
  private _onHoverInCallbacks: Array<(walp: WrappedPolygon) => void> = []
  private _onHoverOutCallbacks: Array<(walp: WrappedPolygon) => void> = []

  get mapSession () { return this._mapSession }

  get uuid () { return this._uuid }
  get id () { return this._polygon.id }
  get polygonId () { return this._polygon.id }
  get geometry (): PolygonGeo { return this._polygon.geometry! }

  get polygon () { return this._polygon }
  set polygon (p: Polygon) {
    this._polygon = p
  
    // TODO: Do something about changing whatever is rendered
  }

  get style () { return this._style }
  get defaultStyle () { return this._defaultStyle }

  get leafletId (): string { return this._leafletId }
  get leafletPolygon (): L.Polygon | undefined { return this._leafletPolygon }

  constructor (polygon: Polygon, mapSession: MapSession, options: WrappedPolygonOptions = DEFAULT_WRAPPED_POLYGON_OPTIONS) {
    this._mapSession = mapSession
    this.polygon = polygon

    if (this.visible) {
      this.renderIfWithinBounds()
    }

    this._mapSession.viewport.onMove(() => {
      if (this.visible) {
        this.renderIfWithinBounds()
      }
    })
  }


  //// START VISIBILITY STATE METHODS
  get visible () { return this._visibilityState.self && this._visibilityState.adminLayer && this._visibilityState.filtered }

  set selfVisibility (value: boolean) {
    this._visibilityState.self = value
    this.reactToVisibilityChange()
  }

  set adminLayerVisibility (value: boolean) {
    this._visibilityState.adminLayer = value
    this.reactToVisibilityChange()
  }

  set filterVisibility (value: boolean) {
    this._visibilityState.filtered = value
    this.reactToVisibilityChange()
  }

  private reactToVisibilityChange () {
    if (this.visible) {
      this.renderIfWithinBounds()
    } else {
      this.unrender()
    }
  }
  //// END VISIBILITY STATE METHODS


  //// START STYLE TRANSFORM METHODS -- These are useful for manually changing the styles
  // of the polygon.
  public setColor (color: string): void {
    this._style.color = color
    this.applyStyles()
  }

  public setFillColor (color: string): void {
    this._style.fillColor = color
    this.applyStyles()
  }

  public setOpacity (opacity: number): void {
    this._style.opacity = opacity
    this.applyStyles()
  }

  public setFillOpacity (opacity: number): void {
    this._style.fillOpacity = opacity
    this.applyStyles()
  }

  public setWeight (weight: number): void {
    this._style.weight = weight
    this.applyStyles()
  }

  public setFill (fill: boolean): void {
    this._style.fill = fill
    this.applyStyles()
  }

  public setLabel(label: string): void {
    if (!label) {
      if (this._tooltip) {
        this._mapSession.renderService.removeLayer(this, this._tooltip)
        this._tooltip = undefined
      }
    } else {
      if (this._tooltip) {
        this._tooltip.setContent(label);
      } else {
        this._tooltip = L.tooltip({
          permanent: true,
          direction: 'center'
        })
          .setLatLng(this.geometry.boundingBoxCenter)
          .setContent(label)
          .addTo(this._mapSession.map);
      }
    }
  }


  public resetStyles () {
    this._style = { ...this._defaultStyle }
    this.applyStyles()
  }

  private applySelectionStyles (selectionGroup: PolygonSelectionGroup) {
    this._appliedSelectionGroupStyles[selectionGroup.id] = selectionGroup.groupStyles
    this.applyStyles()
  }

  private removeSelectionStyles (selectionGroup: PolygonSelectionGroup) {
    delete this._appliedSelectionGroupStyles[selectionGroup.id]
    this.applyStyles()
  }

  private applyFilterStyles (filterStyles: WrappedPolygonStyles) {
    this._appliedFilterStyles = filterStyles
    this.applyStyles()
  }

  private applyStyles () {
    if (this.isRendered) {
      const selectionGroups = Object.keys(this._appliedSelectionGroupStyles).map(key => this._mapSession.getPolygonSelectionGroup(key))
      const aggregateStyles = {
        ...this.defaultStyle,
        ...this.style,
        ...selectionGroups.length === 0 && { ...this._appliedFilterStyles },
        ...selectionGroups.reduce((acc, group) => ({ ...acc, ...group?.groupStyles }), {}),
      }
      this._leafletPolygon!.setStyle(aggregateStyles)
    }
  }
  //// END STYLE TRANSFORM METHODS



  //// START HELPER METHODS
  public distanceFromPoint (point: Coordinate) {
    let referencePoint = new Coordinate(centerOfMass(this.geometry.asGeoJson).geometry)
    const latDiff = referencePoint.lat - point.lat
    const lngDiff = referencePoint.lng - point.lng
    return Math.sqrt(latDiff * latDiff + lngDiff * lngDiff)
  }

  public containsCoordinate (point: Coordinate) {
    if (this.geometry === undefined) {
      return false
    }
    return this.geometry.containsCoordinate(point)
  }

  public flyTo () {
    if (this.geometry === undefined) {
      return
    }
    this._mapSession.flyToBounds(this.geometry.boundingBox.scale(1.15))
  }
  //// END HELPER METHODS


  //// START RENDER METHODS
  public bringToFront (): void {
    if (this.isRendered) {
      this._leafletPolygon!.bringToFront()
    }
  }

  public sendToBack (): void {
    if (this.isRendered) {
      this._leafletPolygon!.bringToBack()
    }
  }

  get isRendered (): boolean {
    return this._leafletPolygon !== undefined
    ? this._mapSession.renderService.hasLayer(this, this._leafletPolygon)
      : false
  }

  get isWithinMapBounds (): boolean {
    if (this.geometry === undefined) {
      return false
    }
    const scaledViewportBbox = this.mapSession.viewport.bbox.scale(1.15)
    return scaledViewportBbox.fullyContainsGeometry(this.geometry)
        || scaledViewportBbox.overlapsGeometry(this.geometry)
  }

  public render (): void {
    if (this.isRendered || this.geometry === undefined) {
      return
    }
    

    this._leafletPolygon = L.polygon(this.geometry.asCoordinatesFlipped, this.style)
    this.mapSession.renderService.addWrappedPolygon(this)
    
    // @ts-ignore -- Ignore that the leaflet_id doesn't exist on the types
    this._leafletId = this._leafletPolygon._leaflet_id.toString()
    this._registerLeafletEvents()

    this.mapSession['_fitToContent']()
  }

  public renderIfWithinBounds (): void {
    if (this.isWithinMapBounds) {
      this.render()
    } else {
      this.unrender()
    }
  }

  public unrender(force?: boolean | false): void {
    if (this._leafletPolygon === undefined) return
    if (this._isImmutable && !force) return

    if (this._mapSession.renderService.hasLayer(this, this._leafletPolygon)) {
      this._mapSession.renderService.removeLayer(this, this._leafletPolygon)
      this._leafletPolygon = undefined
      // this._pushLeafletIdToStore()
    }

    this.unrenderBoundingBox()
  }

  public renderBoundingBox () {
    if (this.geometry === undefined) {
      return undefined
    }
    
    if (this._leafletBoundingBox === undefined) {
      this._leafletBoundingBox = this.mapSession.renderBox(this.geometry.boundingBox, { fillOpacity: 0 })
    }
    return this._leafletBoundingBox
  }

  public unrenderBoundingBox () {
    if (this._leafletBoundingBox !== undefined) {
      this._leafletBoundingBox.remove()
      this._leafletBoundingBox = undefined
    }
  }
  //// END RENDER METHODS


  //// START CALLBACK REGISTRATION METHODS
  public onClick (fn: (walp: WrappedPolygon, point: Coordinate) => void) {
    this._onClickCallbacks.push(fn)
    this._registerLeafletEvents()

    // Deregistration function
    return () => { this._onClickCallbacks = this._onClickCallbacks.filter(cb => cb !== fn) }
  }

  public onHoverIn (fn: (walp: WrappedPolygon) => void) {
    this._onHoverInCallbacks.push(fn)
    this._registerLeafletEvents()

    // Deregistration function
    return () => { this._onHoverInCallbacks = this._onHoverInCallbacks.filter(cb => cb !== fn) }
  }

  public onHoverOut (fn: (walp: WrappedPolygon) => void) {
    this._onHoverOutCallbacks.push(fn)
    this._registerLeafletEvents()

    // Deregistration function
    return () => { this._onHoverOutCallbacks = this._onHoverOutCallbacks.filter(cb => cb !== fn) }
  }
  //// END CALLBACK REGISTRATION METHODS


  private _registerLeafletEvents (): void {
    if (this._leafletPolygon === undefined) return

    if (!this._leafletPolygon.listens('click') && this._onClickCallbacks.length > 0) {
      this._leafletPolygon.on('click', (e: L.LeafletMouseEvent) => {
        this._onClickCallbacks.forEach(fn => fn(this, new Coordinate([e.latlng.lat, e.latlng.lng])))
      })
    }

    if (!this._leafletPolygon.listens('mouseover') && this._onHoverInCallbacks.length > 0) {
      this._leafletPolygon.on('mouseover', (e: L.LeafletMouseEvent) => {
        this._onHoverInCallbacks.forEach(fn => fn(this))
      })
    }

    if (!this._leafletPolygon.listens('mouseout') && this._onHoverOutCallbacks.length > 0) {
      this._leafletPolygon.on('mouseout', (e: L.LeafletMouseEvent) => {
        this._onHoverOutCallbacks.forEach(fn => fn(this))
      })
    }
  }
}