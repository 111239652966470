<script setup lang="ts">
import { ref, computed } from 'vue'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
// @ts-ignore -- No types file exists for this lib
import DateRangePicker from 'vue3-daterange-picker'
import FacebookAdMockup from '@components/facebook_ads/facebook_ad_mockup/FacebookAdMockup.vue'
import StatLinesContainer from './StatLinesContainer.vue'
import Charts from './Charts.vue'
import LineChartPreview from '@components/LineChartPreview.vue'
import ConceptStats from '@components/facebook_ads/dashboard/ConceptStats.vue'

import { fetchBusiness } from '@http/Businesses'
import { queryFacebookStatsForDashboard } from '@http/FacebookStats'
import { showPreloader, hidePreloader } from '@services/preloader'

import type { IQueryFacebookStatsForDashboardResponse } from '@http/FacebookStats'
import type Business from '@models/Business'
import { fetchBrandedSearchPercentage } from '@http/FacebookAdClients'
import type { FacebookAdMockupConfiguration } from '@components/facebook_ads/facebook_ad_mockup/types'

const props = defineProps<{
  facebookAdClientId: number
  businessId: number
}>()

type DateRange = {
  startDate: Date,
  endDate: Date,
}

const loading = ref<boolean>(false)
const adMockupType = ref<'billboard' | 'video'>('billboard')
const billboardAdMockupConfiguration = computed(() => data?.value?.adMockupConfigurations?.find(c => c.type === 'billboard') as FacebookAdMockupConfiguration)
const videoAdMockupConfiguration = computed(() => data?.value?.adMockupConfigurations?.find(c => c.type === 'video') as FacebookAdMockupConfiguration)
const showMockupSwitcher = computed(() => billboardAdMockupConfiguration.value !== undefined && videoAdMockupConfiguration.value !== undefined)

const activeTab = ref('current')
const defaultStartDate = DateTime.now().startOf('month').toJSDate()
const defaultEndDate = DateTime.now().toJSDate()
const dateRange = ref<DateRange>({
  startDate: defaultStartDate,
  endDate: defaultEndDate,
})

const business = ref<Business | undefined>(undefined)
async function getBusinessData () {
  business.value = await fetchBusiness({ businessId: props.businessId })
  console.log(business.value)
}
getBusinessData()

let dataKey = ref<string>(uuid())
const data = ref<IQueryFacebookStatsForDashboardResponse | undefined>(undefined)
async function fetchData() {
  // showPreloader()
  loading.value = true
  data.value = await queryFacebookStatsForDashboard({
    facebookAdClientId: props.facebookAdClientId,
    startDate: DateTime.fromJSDate(dateRange.value.startDate).toFormat('yyyy-MM-dd'),
    endDate: DateTime.fromJSDate(dateRange.value.endDate).toFormat('yyyy-MM-dd'),
  })
  dataKey.value = uuid()
  // hidePreloader()
  loading.value = false
}

fetchData()

function resetDates() {
  dateRange.value = {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  }
}

function onDateSelect(dr: DateRange) {
  dateRange.value = dr
  fetchData()
}

const brandedSearchLabels = ref<string[]>([])
const brandedSearchData = ref<number[]>([])
const brandSearchDataFetching = ref(false)
const brandSearchDataMax = ref(100)

async function fetchBrandedSearchData() {
  try {
    brandSearchDataFetching.value = true
    const data = await fetchBrandedSearchPercentage(props.facebookAdClientId)
    brandedSearchLabels.value = data.map(item => `${item.month} ${item.year}`)
    brandedSearchData.value = data.map(item => parseFloat(item.percentage))
    brandSearchDataFetching.value = false
    const maxValue = Math.max(...brandedSearchData.value)

    // Round to the nearest 10 and add 10
    brandSearchDataMax.value =  Math.ceil(maxValue / 10) * 10 + 10

    console.log(brandedSearchData.value)
  } catch (error) {
    console.error('Error fetching branded search data:', error)
  }
}

</script>

<template>
  <div class="px-8 py-4 bg-gray-card">
    <span class="text-2xl font-bold">{{ business?.nickname }} <span class="font-normal">HyperLCL Dashboard</span></span>
    <div>Be top of mind with your target audience.</div>
  </div>

  <!-- <div class="bg-brand-teal text-white px-8 py-10 shadow-md">
    <div class="space-y-2 max-w-lg">
      <h2 class="text-xl font-semibold text-brand-lightblue">From the Blog</h2>
      <h3 class="text-3xl font-extrabold">Winning the Long Game through Brand Awareness</h3>
      <p class="font-semibold text-brand-lightblue">
        Each month we post the most recent billboard ad that we run to all the named areas of your territory.
        <a href="" class="text-white hover:text-brand-yellow">READ MORE</a>
      </p>
    </div>
  </div> -->

  <div class="h-2 flex flex-row">
    <div class="bg-brand-lightblue basis-1/4"></div>
    <div class="bg-brand-teal basis-1/4"></div>
    <div class="bg-brand-orange basis-1/4"></div>
    <div class="bg-brand-yellow basis-1/4"></div>
  </div>
  
  <!-- Tabs Section Start -->
  <div class="mt-4 px-8">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
        <button @click="activeTab = 'current'; resetDates(); fetchData();"
          :class="{
            'border-brand-orange text-brand-orange': activeTab === 'current',
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'current',
          }"
          class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
        >This Month</button>
        <button @click="activeTab = 'past'; fetchData();"
          :class="{
            'border-brand-orange text-brand-orange': activeTab === 'past',
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'past',
          }"
          class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
        >History</button>
        <!--<button @click="activeTab = 'brandedSearch'"
          :class="{
            'border-brand-orange text-brand-orange': activeTab === 'brandedSearch',
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'brandedSearch',
          }"
          class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
        >Branded Search</button>-->
        <button @click="activeTab = 'brand'"
          :class="{
            'border-brand-orange text-brand-orange': activeTab === 'brand',
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'brand',
          }"
          class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
        >Entire Brand</button>
      </nav>
    </div>

    <div v-if="activeTab === 'current'" class="py-4">

      <!-- ====== iPhone Graphic Start -->
      <div class="grid grid-cols-2 max-w-[800px]">
        <div class="col-span-1 flex flex-col justify-center items-center">
          <div class="h-[700px]">
            <FacebookAdMockup v-if="adMockupType === 'billboard'"
              class="w-[18rem]"
              :configuration="billboardAdMockupConfiguration"
              :loading="loading"
            ></FacebookAdMockup>

            <FacebookAdMockup v-if="adMockupType === 'video'"
              class="w-[18rem]"
              :configuration="videoAdMockupConfiguration"
              :loading="loading"
            ></FacebookAdMockup>
          </div>

          <div class="flex" v-if="showMockupSwitcher">
            <div v-if="billboardAdMockupConfiguration !== undefined" 
              class="w-[90px] text-center px-3 border shadow-md py-1 rounded-l-md" 
              :class="{'bg-blue-primary-lite font-bold text-white': adMockupType === 'billboard', 'cursor-pointer select-none bg-gray-50 hover:bg-gray-100 text-black': adMockupType !== 'billboard'}"
              @click="adMockupType = 'billboard'"
                >
                Billboard
            </div>
            <div v-if="videoAdMockupConfiguration !== undefined" 
              class="w-[90px] text-center px-3 border shadow-md py-1 rounded-r-md"
              :class="{'bg-blue-primary-lite font-bold text-white': adMockupType === 'video', 'cursor-pointer select-none bg-gray-50 hover:bg-gray-100 text-black': adMockupType !== 'video'}"
              @click="adMockupType = 'video'"
              >
                Video
            </div>
          </div>
        </div>

        <StatLinesContainer :loading="loading" :data="data" :business="business" />
      </div>
      <!-- ====== iPhone Graphic End -->

      <Charts
        :loading="loading"
        :data="data"
        :dataKey="dataKey"
      />

      <!-- Current Campaigns Content End -->
    </div>

    <div v-else-if="activeTab === 'past'" class="py-4">
      <!-- Past Campaigns Content Start -->

      <div class="flex justify-between">
        <div class="flex flex-col">
          <div class="font-normal text-sm text-gray-800">Select a Date Range</div>
          <DateRangePicker :opens="'right'" ref="picker" :timePicker="false" :dateRange="dateRange" :autoApply="true"
            :appendToBody="true" @select="onDateSelect" :dark="true"></DateRangePicker>
        </div>
      </div>

      <div class="max-w-[800px]">
        <StatLinesContainer :loading="loading" :data="data" :business="business" :sideBySide="true" />
      </div>

      <Charts
        :loading="loading"
        :data="data"
        :dataKey="dataKey"
      />

      <!-- Past Campaigns Content End -->
    </div>

    <div v-else-if="activeTab === 'brandedSearch'" class="py-4">
      <div class="bg-white rounded-sm border border-stroke px-5 pt-7 pb-5 shadow-md dark:border-dark-strokedark dark:bg-dark-boxdark sm:px-7">
        <h2 class="font-semibold text-brand-orange mb-6">Percentage of Branded Search by Month: </h2>
        
        <div v-if="!brandSearchDataFetching" class="relative h-[500px] w-full">
          <LineChartPreview
            :labels="brandedSearchLabels" 
            :data="brandedSearchData" 
            :options="{ yMax: brandSearchDataMax }"
          />
        </div>
      </div>
    </div>

    <div v-else-if="activeTab === 'brand'" class="py-4">
      <ConceptStats v-if="business !== undefined" :conceptId="business.conceptId" />
    </div>
  </div>
  <!-- Tabs Section End -->
</template>

<style scope>
:root {
  --dark-theme-bg: rgb(26 34 44 / var(--tw-bg-opacity, 1));
  --in-range-bg: rgb(251 133 0 / var(--tw-text-opacity, 1));
}


.dark .reportrange-text,
.dark .calendars,
.dark .calendar-table,
.dark .calendar-table th,
.dark .calendar-table tr,
.dark .calendar-table td {
  background-color: var(--dark-theme-bg);
  color: white;
}

.dark .calendar-table {
  border: none;
}

.dark .calendar-table .prev span,
.dark .calendar-table .next span{
  border: solid white;
  border-width: 0 2px 2px 0;

}

.dark .reportrange-text {
  background: none;
}

.dark .calendar-table td.in-range {
  background-color: var(--in-range-bg);
}

.brand-search {
  height: 600px !important;
}
</style>