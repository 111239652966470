import type { MapFilter, MapFilterAppliedStyles } from "@services/map/MapFilterService"
import type MapSession from "@services/map/MapSession"
import type WrappedPolygon from "@services/map/WrappedPolygon"

/**
 * The evaluation functions are used exclusively by the MapFilterService
 * to evaluate the different types of filters against a set of wrapped
 * admin layer polygons.
 * 
 * Each of these functions operates in the same manner. The outer loop
 * iterates over the wrapped admin layer polygons and the inner loop
 * iterates over the filters. The inner loop evaluates each of the filters
 * against a single wrapped admin layer polygon. The result of the evaluations
 * are all "AND"ed together to determine the final state of
 * the wrapped admin layer polygon. The state is then applied.
 */

export function evaluateAndApplyFilters (mapSession: MapSession, filters: Array<MapFilter>, walps: Array<WrappedPolygon>): void {
  // Split the filters into two groups: ones that effect the visibility of the polygons, and those that don't (and only do styles)
  const visibilityFilters: Array<MapFilter> = [], nonVisibilityFilters: Array<MapFilter> = []
  filters.forEach(filter => {
    // Also reset the passing polygons array, so we can refill it out below
    filter.passingPolygons = []
    if (filter.visibility === true) {
      visibilityFilters.push(filter)
    } else {
      nonVisibilityFilters.push(filter)
    }
  })

  walps.forEach(walp => {
    const stylesToApply: Array<MapFilterAppliedStyles> = []
    
    // Evaluate each visibility filter against this polygon
    // while keeping track of which ones pass so we can apply
    // any styles from it. These styles will be all merged together
    // at the end to create a final set of styles to apply.
    let visibilityState: boolean | undefined = true
    visibilityFilters.forEach(filter => {
      // If the filter is hard specified to a specific admin level,
      // check if the admin level of the filter matches the admin level
      // of the polygon. If it doesn't, we can skip this filter.
      // If the filter is not hard specified to an admin level (it's undefined),
      // we can still evaluate it.

      const pass = filter.evaluate(walp)
      if (pass) {
        filter.passingPolygons.push(walp)
        if (filter.styles !== undefined) {
          stylesToApply.push(filter.styles)
        }
      }

      visibilityState = visibilityState && pass
      return pass
    })
    // Apply the resulting state
    walp.filterVisibility = visibilityState


    // Evaluate each non-visibility filter against this polygon
    // while keeping track of which ones pass so we can apply
    // any styles from it.
    nonVisibilityFilters.forEach(filter => {
      const pass = filter.evaluate(walp)
      if (pass) {
        filter.passingPolygons.push(walp)
        if (filter.styles !== undefined) {
          stylesToApply.push(filter.styles)
        }
      }
    })

    // Merge all passing styles together and apply them
    const mergedStyles = stylesToApply.reduce((acc, styles) => {
      return { ...acc, ...styles }
    }, {})

    // Apply the resulting styles -- executing this method using string
    // syntax because the method is `private`. String accessing lets us get around this.
    // We do this in a few places within the library to avoid making methods public
    // but also allow access to them in certain situations that makes sense.
    walp['applyFilterStyles'](mergedStyles)
  })
}