import L, { Control, LatLng, LatLngBounds, Map, type ControlOptions, DomUtil, DomEvent, type LatLngExpression } from 'leaflet';

interface ZoomHomeOptions extends Control.ZoomOptions {
  position: ControlOptions['position'];
  zoomInText: string;
  zoomInTitle: string;
  zoomOutText: string;
  zoomOutTitle: string;
  zoomHomeIcon: string;
  zoomHomeTitle: string;
  homeCoordinates: LatLng | null;
  homeZoom: number | null;
}

export class ZoomHomeControl extends Control.Zoom {
  options: ZoomHomeOptions;
  private _zoomInButton!: HTMLElement;
  private _zoomOutButton!: HTMLElement;
  private _zoomHomeButton!: HTMLElement;
  protected _map!: Map;

  constructor(options: Partial<ZoomHomeOptions> = {}) {
    super(options);
    this.options = {
      position: 'topleft',
      zoomInText: '+',
      zoomInTitle: 'Zoom in',
      zoomOutText: '-',
      zoomOutTitle: 'Zoom out',
      zoomHomeIcon: 'home',
      zoomHomeTitle: 'Home',
      homeCoordinates: null,
      homeZoom: null,
      ...options,
    };
  }

  onAdd(map: Map): HTMLElement {
    this._map = map;

    const controlName = 'leaflet-control-zoomhome';
    const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
    const options = this.options;

    if (options.homeCoordinates === null) {
      options.homeCoordinates = map.getCenter();
    }
    if (options.homeZoom === null) {
      options.homeZoom = map.getZoom();
    }

    this._zoomInButton = this._createButton(
      options.zoomInText,
      options.zoomInTitle,
      `${controlName}-in`,
      container,
      this._zoomIn.bind(this)
    );

    const zoomHomeText = `<i class="fa fa-${options.zoomHomeIcon}" style="line-height:1.65;"></i>`;
    this._zoomHomeButton = this._createButton(
      zoomHomeText,
      options.zoomHomeTitle,
      `${controlName}-home`,
      container,
      this._zoomHome.bind(this)
    );

    this._zoomOutButton = this._createButton(
      options.zoomOutText,
      options.zoomOutTitle,
      `${controlName}-out`,
      container,
      this._zoomOut.bind(this)
    );

    map.on('zoomend zoomlevelschange', this._updateDisabled, this);

    return container;
  }

  _updateDisabled() {
    //DO nothing
    // const n = this._map;
    // const i = "leaflet-disabled";

    // if (n.getZoom() === n.getMinZoom() && this._zoomOutButton) {
    //   L.DomUtil.addClass(this._zoomOutButton, i);
    // }
    // if (n.getZoom() === n.getMaxZoom() && this._zoomInButton) {
    //   L.DomUtil.addClass(this._zoomInButton, i);
    // }
  }

  setHomeBounds(bounds?: LatLngBounds): void {
    if (!bounds) {
      bounds = this._map.getBounds();
    } else {
      if (!(bounds instanceof L.LatLngBounds)) {
        bounds = L.latLngBounds(bounds as LatLngExpression[]);
      }
    }
    this.options.homeZoom = this._map.getBoundsZoom(bounds);
    this.options.homeCoordinates = bounds.getCenter();
  }

  setHomeCoordinates(coordinates?: LatLng): void {
    if (!coordinates) {
      coordinates = this._map.getCenter();
    }
    this.options.homeCoordinates = coordinates;
  }

  setHomeZoom(zoom?: number): void {
    if (!zoom) {
      zoom = this._map.getZoom();
    }
    this.options.homeZoom = zoom;
  }

  getHomeZoom(): number | null {
    return this.options.homeZoom;
  }

  getHomeCoordinates(): LatLng | null {
    return this.options.homeCoordinates;
  }

  private _zoomIn(e: MouseEvent): void {
    this._map.zoomIn();
  }

  private _zoomOut(e: MouseEvent): void {
    this._map.zoomOut();
  }

  private _zoomHome(e: MouseEvent): void {
    console.log('Zooming home to:', this.options.homeCoordinates, 'with zoom:', this.options.homeZoom);
    this._map.setView(this.options.homeCoordinates!, this.options.homeZoom!);
  }

  private _createButton(
    html: string,
    title: string,
    className: string,
    container: HTMLElement,
    fn: (e: MouseEvent) => void
  ): HTMLElement {
    const link = L.DomUtil.create('a', className, container);
    link.innerHTML = html;
    link.href = '#';
    link.title = title;

    DomEvent.on(link, 'click', DomEvent.preventDefault);
    DomEvent.on(link, 'click', DomEvent.stopPropagation);
    DomEvent.on(link, 'click', (event: Event) => fn(event as MouseEvent));

    return link;
  }
}

export function zoomHomeControl(options: Partial<ZoomHomeOptions> = {}): ZoomHomeControl {
  return new ZoomHomeControl(options);
}
