import { point } from "@turf/turf"
import Geometry from "@services/geometry/Geometry"

import type { Point as GeoJsonPoint, Position} from "geojson"
import type { Position2 } from "@customTypes/gis"

type CoordinateSourceData_Point = GeoJsonPoint
type CoordinateSourceData_LatLng = Array<number>
export type CoordinateSourceData = CoordinateSourceData_Point | CoordinateSourceData_LatLng

function isPoint (data: CoordinateSourceData_Point) {
  return Object.hasOwn(data, 'type') && Object.hasOwn(data, 'coordinates')
}

function isArray (data: CoordinateSourceData_LatLng) {
  return (data.length === 2)
}

export default class Coordinate extends Geometry {
  constructor (source: CoordinateSourceData, flipped: boolean = false) {
    if (isPoint(source as CoordinateSourceData_Point)) {
      source = source as CoordinateSourceData_Point
      super({
        ...source,
        coordinates: flipped
          ? [source.coordinates[1], source.coordinates[0]]
          : [source.coordinates[0], source.coordinates[1]]
      })
    } else if (isArray(source as CoordinateSourceData_LatLng)){
      source = source as CoordinateSourceData_LatLng
      super({
        coordinates: flipped
          ? [source[1], source[0]]
          : [source[0], source[1]] as Position,
        type: "Point"
      })
    }
  }

  get type () { return 'Coordinate' }

  get lat () { return (this.data as GeoJsonPoint).coordinates[0] }
  get lng () { return (this.data as GeoJsonPoint).coordinates[1] }

  get asTurfPoint () { return point([this.lng, this.lat]) }

  get asCoordinates () {
    return [[[this.lat, this.lng]]] as Array<Array<Position2>>
  }

  get asCoordinatesFlipped () {
    return [[[this.lng, this.lat]]] as Array<Array<Position2>>
  }
}