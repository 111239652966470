import BaseModel from "@models/Model"
import Polygon, { type IPolygon } from "@models/Polygon"

import type { DateTime } from 'luxon'

export interface IZipCode {
  id: number 
  type: string
  businessId: number
  createdAt: DateTime
  updatedAt: DateTime
  code: string
  polygon: IPolygon
}

class ZipCode extends BaseModel {
  NAME = 'ZipCode'

  id!: number // This should always exist super important  
  businessId?: number
  createdAt?: DateTime
  updatedAt?: DateTime
  code?: string
  polygon?: Polygon

  constructor (data: IZipCode) {
    Object.keys(data).forEach(key => { (data as IIndexable)[key] = (data as IIndexable)[key] === null ? undefined : (data as IIndexable)[key] })
    super(data)
    Object.assign(this, data)
    console.log("data @model/ZipCode.ts", data)
    this.polygon = new Polygon(data.polygon)
  }

}

export default ZipCode