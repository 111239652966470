// @ts-ignore
import { enter, leave } from 'el-transition'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'backdrop', 'closeButton', 'panel']
  toggleTransition: (event?: Event) => void = () => {}
  leave: (event?: Event) => void = () => {}
  transitioned: boolean = false
  
  declare readonly containerTarget: HTMLElement
  declare readonly backdropTarget: HTMLElement
  declare readonly closeButtonTarget: HTMLElement
  declare readonly panelTarget: HTMLElement

  show () {
    this.containerTarget.classList.remove("hidden")
    enter(this.backdropTarget)
    enter(this.closeButtonTarget)
    enter(this.panelTarget)
  }

  hide() {
    const promises = [
      leave(this.backdropTarget, 'a'),
      leave(this.closeButtonTarget, 'd'),
      leave(this.panelTarget, 'c'),
    ]
    Promise.all(promises).then(() => {
      this.containerTarget.classList.add("hidden")
    })
  }
}
