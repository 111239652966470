import { v4 as uuid } from "uuid"
import { circle } from "@turf/turf"

import Polygon from "@services/geometry/Polygon"
import { evaluateAndApplyFilters } from "@services/map/utils/evaluations"

import type WrappedPolygon from "@services/map/WrappedPolygon"
import type MapSession from "@services/map/MapSession"
import type { WrappedPolygonStyles } from "@services/map/WrappedPolygon"

type MapFilterColumnCriteria = {
  id?: number | ((walp: WrappedPolygon) => boolean)
  uuid?: string | ((walp: WrappedPolygon) => boolean)
}

enum FilterMatchMode {
  MATCH = 'match',
  RADIAL = 'radial',
}

type MapFilterRadialOptions = { lat: number, lng: number, radiusKm: number }
export type MapFilterAppliedStyles = { [Property in keyof WrappedPolygonStyles]: WrappedPolygonStyles[Property] }

type IMatchMapFilter = { matchMode: FilterMatchMode.MATCH, match: MapFilterColumnCriteria, styles?: MapFilterAppliedStyles }
type IRadialMapFilter = { matchMode: FilterMatchMode.RADIAL, radial: MapFilterRadialOptions, styles?: MapFilterAppliedStyles }

interface IMapFilter {
  id: string
  matchMode: FilterMatchMode
  visibility?: boolean
  styles?: MapFilterAppliedStyles
  passingPolygons: Array<WrappedPolygon>
  evaluate (walp: WrappedPolygon): boolean
}

export class MatchMapFilter implements IMapFilter, IMatchMapFilter {
  public id: string = uuid()
  public matchMode: FilterMatchMode.MATCH = FilterMatchMode.MATCH
  public match!: MapFilterColumnCriteria

  public visibility?: boolean
  public styles?: MapFilterAppliedStyles
  public passingPolygons: Array<WrappedPolygon> = []

  public evaluate (wp: WrappedPolygon): boolean {
    const criteriaToEvaluate = Object.keys(this.match) as Array<keyof MapFilterColumnCriteria>
    return criteriaToEvaluate.every((criterion) => {
      if (typeof this.match[criterion] === 'function') {
        const evaluationFn = this.match[criterion] as (wp: WrappedPolygon) => boolean
        return evaluationFn(wp)
      }
      return this.match[criterion] === wp[criterion]
    })
  }
}

export class RadialMapFilter implements IMapFilter, IRadialMapFilter {
  public id: string = uuid()
  public matchMode: FilterMatchMode.RADIAL = FilterMatchMode.RADIAL
  public radial!: MapFilterRadialOptions

  public visibility?: boolean
  public styles?: MapFilterAppliedStyles
  public passingPolygons: Array<WrappedPolygon> = []

  public evaluate (walp: WrappedPolygon): boolean {
    const turfCircle = circle([this.radial.lng, this.radial.lat], this.radial.radiusKm, { units: 'kilometers' })
    const turfCirclePoly = new Polygon(turfCircle.geometry)
    return turfCirclePoly.geometryIntersects(new Polygon(walp.geometry.data))
  }
}

export type MapFilter = MatchMapFilter | RadialMapFilter



/**
 * The MapFilterService is responsible for managing the filters that are applied to the map.
 */
class MapFilterService {
  private _mapSession: MapSession
  private _filters: { [Interface: string]: MapFilter } = {}

  constructor (mapSession: MapSession) {
    this._mapSession = mapSession
  }

  public addFilter (filter: MapFilter) {
    this._filters[filter.id] = filter
    this.evaluateAllPolygons()
    return () => this.removeFilter(filter)
  }

  public removeFilter (filter: MapFilter | string, reevaluate: boolean = true) {
    const filterId = typeof filter === 'string' ? filter : filter.id
    delete this._filters[filterId]

    // This is conditional so we can disable it when performing
    // the `clearFilters` operation, so we don't reevaluate every
    // time we remove 1 filter when removing multiple. See
    // `clearFilters` method.
    if (reevaluate) {
      this.evaluateAllPolygons()
    }
  }

  public clearFilters () {
    const filters = Object.values(this._filters)
    for (const filter of filters) {
      this.removeFilter(filter, false)
    }
    this.evaluateAllPolygons()
  }

  public evaluate (walps: Array<WrappedPolygon>) {
    evaluateAndApplyFilters(this._mapSession, Object.values(this._filters), walps)
  }

  // Reevaluates the filters against all polygons in the store
  // in the MapSession.
  private evaluateAllPolygons () {
    const store = this._mapSession.wrappedPolygonStore
    this.evaluate(store.wrappedPolygons)
  }
}

export default MapFilterService